import { FC, useState, useEffect } from 'react'

import { colors } from '../../../../constants/color'
import styles from './profitLossIncomeModal.module.scss'
import { CloseIcon } from '../../../../components/common/svg-components'
import { incomeBalanceTableHeaderData } from '../profitLossTableData'
import Pagination from '../../../../components/common/pagination/Pagination'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

interface IProfitLossIncomeModal {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const ProfitLossIncomeModal: FC<IProfitLossIncomeModal> = ({
  heading,
  handleClose,
  popData,
}) => {
  const [totalCreditAmount, setTotalCreditAmount] = useState(0)
  const [totalDebitAmount, setTotalDebitAmount] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  // Credit Amount
  useEffect(() => {
    const calculatedTotalCreditAmount = popData?.viewBalance?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.credit_amount || 0)
        return sum + creditAmount
      },
      0
    )
    const calculatedTotalDebitAmount = popData?.viewBalance?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.debit_amount || 0)
        return sum + creditAmount
      },
      0
    )
    setTotalCreditAmount(calculatedTotalCreditAmount)
    setTotalDebitAmount(calculatedTotalDebitAmount)
  }, [popData?.viewBalance])
  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()
  return (
    <div
      className={styles.balanceViewModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.balanceViewModalHeading}>
        {t('Reports.Income_Details')}
      </h1>
      <hr className={styles.balanceViewDivider} />
      <div className={styles.tableData}>
        <TableV2
          tableHeaderData={incomeBalanceTableHeaderData ?? []}
          tableRowData={popData?.viewBalance ?? []}
          active={false}
        />
      </div>
      {/* Pagination */}
      {popData?.viewBalance?.length > 9 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
      <div className={styles.tableLabelContainer}>
        <span className={styles.creditText}>
          {t('Voucher.Total_Credit_Amount')}: $ {totalCreditAmount}
        </span>
        <span className={styles.debitText}>
          {t('Voucher.Total_Debit_Amount')}: $ {totalDebitAmount}
        </span>
      </div>
    </div>
  )
}

export default ProfitLossIncomeModal
