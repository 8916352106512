import {
  GET__ROLE__USER,
  REPORT_POC_TYPE,
  UPDATE_REPORT_MAPPING_TYPE,
  UPDATE_ROLE_STATUS_TYPE,
  CREATE_REPORT_QUERY_TYPE,
  UPDATE_REPORT_QUERY_TYPE,
  GET_REPORT_BUILDER_CONFIG_TYPE,
  GET_REPORT_BUILDER_TYPE,
  GET_REPORTS_TYPE,
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  UPDATE_REPORT_STATUS_BY_ID_TYPE,
  PATIENT_LIST_REPORT,
  INVOICE_WISE_SALES,
  SERVICE_WISE_SALES_SUMMARY,
  COLLECTION_DAY_WISE_SUMMARY,
  DOCTOR_WISE_COLLECTION_SUMMARY,
  SERVICE_WISE_SALES,
  PATIENT_STATEMENT,
  PATIENT_SUMMARY_TYPE,
  REVENUE_TYPE,
  PATIENT_SUMMARY_FILTER,
  REVENUE_ANYLYTICS_FILTER,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  getRoleUser,
  reportMapping,
  reportPoc,
  updaterolestatus,
  createReportQuery,
  updateReportBuilderQuery,
  getReportBuilderConfig,
  getReportBuilder,
  getReportsType,
  getDynamicReportByQuery,
  updateReportStatus,
  getAllPatientListReport,
  invoiceWiseSales,
  serviceWiseSalesSummary,
  collectionDayWiseSummary,
  doctorWiseCollections,
  serviceWiseSales,
  patientStatements,
  patientSummary,
  revenue,
  patientSummaryFilter,
  RevenueAnylticsFilter,
} from './roleCrud'

export const getUserRole = createAsyncThunkForSlice(
  GET__ROLE__USER,
  getRoleUser
)

export const updateRoleStatus = createAsyncThunkForSlice(
  UPDATE_ROLE_STATUS_TYPE,
  updaterolestatus
)

export const updateReportMapping = createAsyncThunkForSlice(
  UPDATE_REPORT_MAPPING_TYPE,
  reportMapping,
  { isToast: true }
)

export const reportPocAsync = createAsyncThunkForSlice(
  REPORT_POC_TYPE,
  reportPoc,
  { isToast: true }
)

export const addReportQuery = createAsyncThunkForSlice(
  CREATE_REPORT_QUERY_TYPE,
  createReportQuery,
  { isToast: true }
)

export const updateReportQuery = createAsyncThunkForSlice(
  UPDATE_REPORT_QUERY_TYPE,
  updateReportBuilderQuery,
  { isToast: true }
)

export const getReportBuilderConfigList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_CONFIG_TYPE,
  getReportBuilderConfig
)

export const getReportBuilderList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_TYPE,
  getReportBuilder
)

export const getReportsList = createAsyncThunkForSlice(
  GET_REPORTS_TYPE,
  getReportsType
)

export const getDynamicReportQueryData = createAsyncThunkForSlice(
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  getDynamicReportByQuery,
  { isToast: true }
)

export const updateReportStatusById = createAsyncThunkForSlice(
  UPDATE_REPORT_STATUS_BY_ID_TYPE,
  updateReportStatus,
  { isToast: true }
)

export const getAllPatientListsReport = createAsyncThunkForSlice(
  PATIENT_LIST_REPORT,
  getAllPatientListReport,
  { isToast: true }
)

export const invoiceWiseSale = createAsyncThunkForSlice(
  INVOICE_WISE_SALES,
  invoiceWiseSales,
  { isToast: true }
)

export const serviceWiseSaleSummary = createAsyncThunkForSlice(
  SERVICE_WISE_SALES_SUMMARY,
  serviceWiseSalesSummary,
  { isToast: true }
)

export const collectionsDayWiseSummary = createAsyncThunkForSlice(
  COLLECTION_DAY_WISE_SUMMARY,
  collectionDayWiseSummary,
  { isToast: true }
)

export const doctorWiseCollection = createAsyncThunkForSlice(
  DOCTOR_WISE_COLLECTION_SUMMARY,
  doctorWiseCollections,
  { isToast: true }
)

export const serviceWiseSale = createAsyncThunkForSlice(
  SERVICE_WISE_SALES,
  serviceWiseSales,
  { isToast: true }
)

export const patientStatement = createAsyncThunkForSlice(
  PATIENT_STATEMENT,
  patientStatements,
  { isToast: true }
)

// patient summary report
export const patientSummaryReport = createAsyncThunkForSlice(
  PATIENT_SUMMARY_TYPE,
  patientSummary
)

export const revenueDetail = createAsyncThunkForSlice(REVENUE_TYPE, revenue)


// patient summary filter

export const patientSummaryFilterUrl = createAsyncThunkForSlice(
  PATIENT_SUMMARY_FILTER,
  patientSummaryFilter
)


export const RevenueAnylticsFilterUrl = createAsyncThunkForSlice(
  REVENUE_ANYLYTICS_FILTER,
  RevenueAnylticsFilter
)

