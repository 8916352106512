import Select from 'react-select'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/common/input/input'
import Button from '../../components/common/button/Button'
import { useEffect, useState } from 'react'
import { ErrorMessage } from '../../components/common/error-message-component'
import { Label } from '../../components/common/label'
import { getAllDepartmentDropdownData } from '../../redux/features/department/departmentAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAllDoctors } from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import { cards, reportCategory, reportType } from './data'
import {
  GENERATE_CARDS_REPORTS,
  GENERATE_DEPT_REPORT,
  GENERATE_DOCTOR_SERVICE_REPORT,
  GENERATE_PATIENT_OUTSTANDING_REPORTS,
  GENERATE_PATIENT_SOURCE_REPORTS,
  PATIENT_LIST_REPORT,
} from '../../config/config'
import {
  collectionDayWiseReportPdf,
  doctorWiseCollectionReportPdf,
  invoiceWiseSalesReportPdf,
  patientListReportPdf,
  patientStatementReportPdf,
  requestToDownloadPdf,
  serviceWiseSaleSummaryReportPdf,
  serviceWiseSalesReportPdf,
  userWiseCollectionReportPdf,
} from '../../utils/utils'
import {
  getAllPatientList,
  getPatientSelectionList,
} from '../../redux/features/patient-emr/patient/patientAsyncAction'
import Loader from '../../components/common/spinner/Loader'
import styles from './style.module.scss'
import { colors } from '../../constants/color'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import {
  collectionsDayWiseSummary,
  doctorWiseCollection,
  getAllPatientListsReport,
  invoiceWiseSale,
  patientStatement,
  serviceWiseSale,
  serviceWiseSaleSummary,
} from '../../redux/features/role/roleAsynActions'
import Popup from '../../components/common/popup/Popup'
import SearchModal from '../../components/common/modal/search-modal/SearchModal'
import { getAllBranch } from '../../redux/features/branch/branchAsyncActions'
import { t } from 'i18next'

const PreBuildReportBuilder = () => {
  const { departmentDropdownData } = useAppSelector((state) => state.department)
  const { branchData } = useAppSelector((state) => state.login)
  const { doctorData } = useAppSelector((state) => state.appointments)
  const { nationalityData, patientListData } = useAppSelector(
    (state) => state.patient
  )
  const [reportTypeData, setReportTypeData] = useState<any[]>([])
  const [pdfLoading, setPdfLoading] = useState(false)
  const [isAttend, setIsAttend] = useState<boolean>(false)

  const { isLoading } = useAppSelector((state) => state.roleUser)

  const [patientModal, setPatientModal] = useState<boolean>(false)
  const [patientId, setPatientId] = useState()
  const { branchData: branchList } = useAppSelector((state) => state.branch)
  const [patientName, setPatientName] = useState()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm()

  const watchReportType = watch('reportType')?.label

  //Function to generate The dynamic fields as Per the report type
  const generateDynamicInputData = () => {
    let data: any = []
    let label = ''
    let requiredField = false
    if (watchReportType === 'Dept report') {
      data = departmentDropdownData?.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      label = t('DepartmentSetup.Dept')
      requiredField = true
    } else if (watchReportType === 'Doctor report') {
      data = doctorData?.map((item: any) => {
        return {
          label: item?.doctor_name,
          value: item?._id,
        }
      })
      label = t('Receptionist Dashboard.Doctor')
      requiredField = true
    } else if (watchReportType === 'Card-summary') {
      data = cards
      label = t('ReportGenerator.Cards')
      requiredField = false
    } else if (watchReportType === 'Patient-source') {
      data = nationalityData?.[0]?.values?.map((item: any) => {
        return {
          label: item?.value,
          value: item?._id,
        }
      })
      label = t('PatientEMR.Source')
      data =
        data && data?.length > 0
          ? [{ label: 'Select All', value: '' }, ...data]
          : [{ label: 'Select All', value: '' }]
      requiredField = true
    } else if (watchReportType === 'Patient-outstanding') {
      data = patientListData?.map((item: any) => {
        return {
          label: item?.patient_name,
          value: item?._id,
        }
      })
      label = t('ViewAppointment.Patient')
      requiredField = false
    } else if (watchReportType === 'Patient List') {
      requiredField = true
    } else if (watchReportType === 'Invoice Wise Sales') {
      requiredField = true
    } else if (watchReportType === 'Service Wise Sales') {
      requiredField = true
    } else if (watchReportType === 'Service Wise Sales Summary') {
      requiredField = true
    } else if (watchReportType === 'Patient Statement') {
      requiredField = true
    } else if (watchReportType === 'User Wise Collection Summary') {
      requiredField = true
    } else if (watchReportType === 'Day Wise Collection Summary') {
      requiredField = true
    } else if (watchReportType === 'Collection Summary') {
      requiredField = true
    }

    return { data, label, requiredField }
  }

  const checkPatientOutStanding =
    generateDynamicInputData()?.label === 'Patient'

  const dispatch = useAppDispatch()

  //To mount the dropdown data as per the report type selction
  useEffect(() => {
    let reqData = {
      is_active: true,
      page: 1,
      pageSize: 1000,
    }
    let dataPayload = {
      category_name: 'SOURCE_AD_CAMPAIGN',
    }
    const requestData = {
      search: '',
      page: 1,
      pageSize: 1000,
      is_active: true,
    }

    if (watchReportType === 'Dept report') {
      dispatch(getAllDepartmentDropdownData(requestGenerator(reqData)))
    } else if (watchReportType === 'Doctor report') {
      dispatch(getAllDoctors(requestGenerator(reqData)))
    } else if (watchReportType === 'Patient-outstanding') {
      dispatch(getAllPatientList(requestGenerator(requestData)))
    } else if (watchReportType === 'Patient-source') {
      dispatch(getPatientSelectionList(requestGenerator(dataPayload)))
    }
  }, [dispatch, watchReportType])

  useEffect(() => {
    let data = {
      search: '',
      page: 1,
      pageSize: 1000,
      order_by: { is_default: -1 },
    }
    dispatch(getAllBranch(requestGenerator(data)))
  }, [])

  const onSubmit = (item: any) => {
    if (watchReportType === 'Card-summary') {
      let data = {
        methods: item?.dynamicField?.map((s: any) => s?.value),
        startDate: item?.dateFrom?.length ? item?.dateFrom : undefined,
        endDate: item?.dateTo?.length ? item?.dateTo : undefined,
      }
      requestToDownloadPdf(
        GENERATE_CARDS_REPORTS,
        requestGenerator(data),
        'cards',
        setPdfLoading
      )
    } else if (watchReportType === 'Patient-outstanding') {
      let data = {
        patient_ids: item?.dynamicField?.map((s: any) => s.value),
      }
      requestToDownloadPdf(
        GENERATE_PATIENT_OUTSTANDING_REPORTS,
        requestGenerator(data),
        'patient-outstanding',
        setPdfLoading
      )
    } else if (watchReportType === 'Patient-source') {
      const payloadData = item?.dynamicField?.map((s: any) => s.label)
      const source = payloadData?.filter((item: any) => item !== 'Select All')
      let data = {
        startDate: item?.dateFrom?.length ? item?.dateFrom : undefined,
        endDate: item?.dateTo?.length ? item?.dateTo : undefined,
        sources: source,
      }
      requestToDownloadPdf(
        GENERATE_PATIENT_SOURCE_REPORTS,
        requestGenerator(data),
        'patient-source',
        setPdfLoading
      )
    } else if (watchReportType === 'Doctor report') {
      let data = {
        doctor_ids: item?.dynamicField?.map((s: any) => s.value),
        range: {
          fromDate: item?.dateFrom,
          toDate: item?.dateTo,
        },
      }
      requestToDownloadPdf(
        GENERATE_DOCTOR_SERVICE_REPORT,
        requestGenerator(data),
        'doctor-report',
        setPdfLoading
      )
    } else if (watchReportType === 'Dept report') {
      let data = {
        department_id: item?.dynamicField?.map((s: any) => s.value),
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
      }
      requestToDownloadPdf(
        GENERATE_DEPT_REPORT,
        requestGenerator(data),
        'dept-report',
        setPdfLoading
      )
    } else if (watchReportType === 'Patient List') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
        isAttend: isAttend,
      }
      dispatch(getAllPatientListsReport(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/getAllPatientList/fulfilled') {
          patientListReportPdf(e?.payload, item?.dateFrom, item?.dateTo)
        }
      })
    } else if (watchReportType === 'Invoice Wise Sales') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
        branch_id:
          watch('branch_id')?.length > 0
            ? watch('branch_id')?.map((item: any) => item?.value)
            : '',
      }
      dispatch(invoiceWiseSale(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/getInvoiceWiseSales/fulfilled') {
          invoiceWiseSalesReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    } else if (watchReportType === 'Service Wise Sales') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
      }
      dispatch(serviceWiseSaleSummary(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/getServiceWiseSalesSummary/fulfilled') {
          serviceWiseSalesReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    } else if (watchReportType === 'Day Wise Collection Summary') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
      }
      dispatch(collectionsDayWiseSummary(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/getCollectionDayWiseSummary/fulfilled') {
          collectionDayWiseReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    } else if (watchReportType === 'Collection Summary') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
      }
      dispatch(doctorWiseCollection(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/doctorWiseCollection/fulfilled') {
          doctorWiseCollectionReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    } else if (watchReportType === 'Service Wise Sales Summary') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
      }
      dispatch(serviceWiseSale(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/serviceWiseSales/fulfilled') {
          serviceWiseSaleSummaryReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    } else if (watchReportType === 'Patient Statement') {
      let data = {
        patient_id: patientId,
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
        type: 'DETAILED',
      }
      dispatch(patientStatement(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/patientWiseCollection/fulfilled') {
          patientStatementReportPdf(e?.payload?.data[0]?.invoiceData)
        }
      })
    } else if (watchReportType === 'User Wise Collection Summary') {
      let data = {
        startDate: item?.dateFrom,
        endDate: item?.dateTo,
        type: 'BASIC',
      }
      dispatch(patientStatement(requestGenerator(data))).then((e) => {
        if (e?.type === 'reports/patientWiseCollection/fulfilled') {
          userWiseCollectionReportPdf(
            e?.payload?.data,
            item?.dateFrom,
            item?.dateTo
          )
        }
      })
    }
  }
  useEffect(() => {
    let tempArr: any[] = []

    branchData?.role_id?.reports_access &&
      branchData?.role_id?.reports_access?.length > 0 &&
      branchData?.role_id?.reports_access?.forEach((item: any) => {
        const type: any = reportType?.find(
          (repType: any) => repType?.value === item
        )
        if (type) {
          tempArr.push(type)
        }
      })

    setReportTypeData(tempArr)
  }, [branchData])

  return (
    <>
      {pdfLoading ? <Loader /> : ''}
      {isLoading && <Loader />}
      {patientModal && (
        <Popup
          Children={SearchModal}
          handleRowClick={(e: any) => {
            setPatientId(e?._id)
            setPatientName(e?.patient_name)
            setPatientModal(false)
          }}
          handleClose={() => setPatientModal(false)}
        />
      )}
      <form
        className={styles.reportContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.formFields}>
          {/* <div style={{ flexBasis: "30%" }}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText="Report Category"
              requiredField={true}
              flexBasis="31%"
            />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder="Select category"
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                value={watch("reportCategory")}
                options={reportCategory}
                {...register("reportCategory", { required: true })}
                isSearchable={false}
                onChange={(e: any) => {
                  setCategoryType(e.reportType);
                  setValue("reportCategory", e);
                  setValue("reportType", null);
                  setValue("dynamicField", null);
                  clearErrors("dynamicField");
                  clearErrors("dateTo");
                  clearErrors("dateFrom");
                }}
                maxMenuHeight={200}
              />
            </div>
          </div>
          {errors?.reportCategory?.type === "required" && (
            <ErrorMessage message="Please select report category" />
          )}
        </div> */}
          <div style={{ flexBasis: '30%' }}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('ReportMapping.Report_Type')}
                requiredField={true}
                flexBasis="31%"
              />
              <div className={styles.inlineItems}>
                <Select
                  className={styles.selectInputField}
                  placeholder={t('ReportMapping.Report_Type_Placeholder')}
                  closeMenuOnSelect={true}
                  components={{ DropdownIndicator }}
                  value={watch('reportType')}
                  options={reportTypeData}
                  {...register('reportType', { required: true })}
                  isSearchable={false}
                  onChange={(e: any) => {
                    setValue('reportType', e)
                    setValue('dynamicField', null)
                    clearErrors('dynamicField')
                    clearErrors('dateTo')
                    clearErrors('dateFrom')
                    setPatientId(undefined)
                    setPatientName(undefined)
                  }}
                  maxMenuHeight={200}
                />
              </div>
            </div>
            {errors?.reportType?.type === 'required' && (
              <ErrorMessage
                message={t('ReportMapping.report_type_validation')}
              />
            )}
          </div>
        </div>
        <div className={styles.formInputFields}>
          {watchReportType !== 'Patient-outstanding' &&
            watchReportType !== undefined && (
              <>
                <div style={{ flexBasis: '30%', marginBlock: '20px' }}>
                  <Input
                    requiredField={generateDynamicInputData()?.requiredField}
                    type="date"
                    labelText={t('ReportGenerator.Date_From') || 'Date From'}
                    {...register('dateFrom', {
                      required: generateDynamicInputData()?.requiredField,
                    })}
                    showErrors={errors?.dateFrom?.type === 'required'}
                    errorMessage={t('ReportGenerator.Date_From_Validation')}
                    customClass={styles.datePicker}
                  />
                </div>
                <div
                  style={{
                    flexBasis: '30%',
                    marginBlock: '20px',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    requiredField={generateDynamicInputData()?.requiredField}
                    type="date"
                    labelText={t('ReportGenerator.Date_To') || 'Date To'}
                    {...register('dateTo', {
                      required: generateDynamicInputData()?.requiredField,
                    })}
                    showErrors={errors?.dateTo?.type === 'required'}
                    errorMessage={t('ReportGenerator.Date_To_Validation')}
                    customClass={styles.datePicker}
                  />
                </div>
                {watchReportType === 'Patient List' && (
                  <div style={{ flexBasis: '30%', marginBlock: '20px' }}>
                    <label
                      style={{ margin: '0px 20px 0 30px' }}
                      className={styles.labelText}
                    >
                      {t('ReportGenerator.Is_Attend')} ?
                    </label>
                    {isAttend ? (
                      <CheckIcon
                        fillColor={colors.green1}
                        handleClick={() => setIsAttend(false)}
                      />
                    ) : (
                      <UncheckIcon
                        fillColor={colors.grey1}
                        handleClick={() => setIsAttend(true)}
                      />
                    )}
                  </div>
                )}
                {watchReportType === 'Invoice Wise Sales' && (
                  <div
                    style={{
                      flexBasis: '30%',
                      marginBlock: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <label
                      style={{ margin: '0px 20px 0 30px' }}
                      className={styles.labelText}
                    >
                      {t('Header.Branch')}
                    </label>
                    <Select
                      className={styles.selectBranch}
                      placeholder={
                        t('ManageStaff.Branch_placeholder') || 'Select Branch'
                      }
                      closeMenuOnSelect={true}
                      components={{ DropdownIndicator }}
                      value={watch('branch_id') || []}
                      isMulti
                      options={branchList?.map((item: any) => {
                        return {
                          label: item?.name,
                          value: item?._id,
                        }
                      })}
                      isSearchable={false}
                      onChange={(e) => {
                        setValue(
                          'branch_id',
                          e.map((item: any) => {
                            return item
                          })
                        )
                      }}
                      maxMenuHeight={200}
                    />
                  </div>
                )}
                {watchReportType === 'Patient Statement' && (
                  <div
                    style={{
                      flexBasis: '30%',
                      marginBlock: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      title={t('IPDBooking.SelectPatient') || 'Select Patient'}
                      type="button"
                      handleClick={() => setPatientModal(true)}
                    />
                    <p>{patientName}</p>
                  </div>
                )}
              </>
            )}
          {!!generateDynamicInputData()?.label?.length && (
            <div style={{ flexBasis: '30%', marginBlock: '20px' }}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={generateDynamicInputData()?.label}
                  requiredField={checkPatientOutStanding ? false : true}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={`${t(
                      'ReportGenerator.Select'
                    )} ${generateDynamicInputData()?.label?.toLowerCase()}`}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    value={watch('dynamicField')}
                    options={generateDynamicInputData()?.data}
                    {...register('dynamicField', {
                      required: checkPatientOutStanding ? false : true,
                    })}
                    isSearchable={checkPatientOutStanding}
                    onChange={(e: any) => {
                      const found = e?.some(
                        (item: any) => item?.label === 'Select All'
                      )
                      const selectedData =
                        generateDynamicInputData()?.data &&
                        generateDynamicInputData()?.data?.length > 0
                          ? generateDynamicInputData()?.data?.filter(
                              (item: any) => item?.label !== 'Select All'
                            )
                          : []
                      if (found) {
                        setValue('dynamicField', selectedData)
                      } else {
                        setValue('dynamicField', e)
                      }
                    }}
                    maxMenuHeight={200}
                    isMulti={true}
                  />
                </div>
              </div>
              {errors?.dynamicField?.type === 'required' && (
                <ErrorMessage
                  message={`${t(
                    'ReportGenerator.Please_select'
                  )} ${generateDynamicInputData()?.label?.toLowerCase()}`}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.submitBtn}>
          <Button
            title={t('ReportGenerator.Generate_Report') || 'Generate Report'}
            disable={
              watchReportType === 'Patient Statement' && patientId == undefined
                ? true
                : false
            }
          />
        </div>
      </form>
    </>
  )
}

export default PreBuildReportBuilder
