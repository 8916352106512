import { FC, useEffect, useState } from 'react'
import styles from './settledInvoiceModal.module.scss'
import { CloseIcon, SearchButton } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import { requestToDownloadPdf, trimValue } from '../../../../utils/utils'
import TableV3 from '../../table/tableV3/TableV3'
import Pagination from '../../pagination/Pagination'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { settledInvoiceHeaderData } from '../../../../constants/table-data/userTableData'
import { Cols } from '../../../../interfaces/interfaces'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { getSettledInvoiceList } from '../../../../redux/features/invoice-module/invoiceAsynActions'
import Loader from '../../spinner/Loader'
import Popup from '../../popup/Popup'
import SettledEmailPopup from '../settled-email-popup/SettledEmailPopup'
import { getSettledInvoiceViewDetails } from '../../../../redux/features/lab-invoice/labInvoiceAsyncActions'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { clearTemplateData } from '../../../../redux/features/dynamic-template/dynamicTemplateSlice'
import { SETTLED_INVOICE_VIEW } from '../../../../config/config'
import { getAllTemplates } from '../../../../redux/features/dynamic-template/dynamicTemplateAsyncActions'
import { GET_TEMPLATE_TYPE } from '../../../../constants/asyncActionsType'

interface ISettledInvoiceModal {
  setModelOpenClose?: any
  handleRowClick?: any
  handleClose?: any
  message: string
  popData: any
}

const SettledInvoiceModal: FC<ISettledInvoiceModal> = ({
  setModelOpenClose,
  handleRowClick,
  handleClose,
  message,
  popData,
}) => {
  const [patientReceiptData, setpatientReceiptData] = useState({})
  const [showSettleEmailPopup, setshowSettleEmailPopup] = useState(false)
  const [pdfLoader, setPdfLoader] = useState(false)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, settledInvoiceList, settledInvoiceListObject } =
    useAppSelector((state) => state.invoice)
  const templateState = useAppSelector((state) => state.template)
  // React Table define
  const data: Cols[] = settledInvoiceList
  const columns: Column<Cols>[] = settledInvoiceHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [searchValue, setSearchValue] = useState<string>('')
  const [templateOptions, setTemplateOptions] = useState<any[]>([])
  const [activateSmartSearch, setActivateSmartSearch] = useState<boolean>(false)

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let payloadData = {
      type: message,
      search: searchValue,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    if (popData?.patient_id) {
      dispatch(
        getSettledInvoiceList(
          requestGenerator({ ...payloadData, patient_id: popData?.patient_id })
        )
      ).then((result) => setTotalPage(result.payload.lastPage))
    } else {
      dispatch(getSettledInvoiceList(requestGenerator(payloadData))).then(
        (result) => setTotalPage(result.payload.lastPage)
      )
    }
  }, [dispatch, pageIndex, dataPerPage])

  useEffect(() => {
    dispatch(
      getAllTemplates(
        requestGenerator({ type: 'invoice', page: 1, pageSize: 1000 })
      )
    ).then((e) => {
      if (e.type === `${GET_TEMPLATE_TYPE}/fulfilled`) {
        const options = e.payload?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?._id,
          }
        })
        setTemplateOptions([{ label: 'Default', value: 'default' }, ...options])
      }
    })
  }, [dispatch])

  const handleInputSearch = () => {
    setActivateSmartSearch(true)
    const requestData = {
      type: message,
      search: searchValue,
      page: 1,
      pageSize: dataPerPage,
    }
    dispatch(getSettledInvoiceList(requestGenerator(requestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        // if (result.payload.nextPage !== 0) {
        //   setPageIndex(result.payload.nextPage)
        // }
      }
    )
  }

  const handleView = (item: any) => {
    setpatientReceiptData(item)
    setshowSettleEmailPopup(true)
  }

  const sendEmail = (data: any) => {
    let payload = {
      _id: data?._id,
      email: data?.patient_email,
      offset: moment()?.utcOffset(),
      view_id: data?.view_id,
    }
    if (data?.isPdf) {
      requestToDownloadPdf(
        SETTLED_INVOICE_VIEW,
        requestGenerator({ ...payload, is_email: false }),
        's',
        setPdfLoader,
        'print'
      )
      setshowSettleEmailPopup(false)
      dispatch(clearTemplateData())
    } else {
      dispatch(
        getSettledInvoiceViewDetails(
          requestGenerator({ ...payload, is_email: true })
        )
      ).then((result) => {
        setshowSettleEmailPopup(false)
        dispatch(clearTemplateData())
      })
    }
  }
  return (
    <>
      {isLoading || pdfLoader || templateState?.isLoading ? <Loader /> : ''}

      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {showSettleEmailPopup && (
          <Popup
            Children={SettledEmailPopup}
            handleClose={() => setshowSettleEmailPopup(!showSettleEmailPopup)}
            setModelOpenClose={() =>
              setshowSettleEmailPopup(!showSettleEmailPopup)
            }
            popData={{
              ...patientReceiptData,
              options: templateOptions,
            }}
            handleYes={(data: any) => sendEmail(data)}
          />
        )}

        <div className={styles.closeIconContainer}>
          <CloseIcon
            customClass={styles.closeIconStyle}
            fillColor={colors.green1}
            handleClick={() => handleClose()}
          />
        </div>
        <p className={styles.title}>{t('SettledInvoice.All Invoices')}</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.searchFieldContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '25px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder={t('Common.Search Patients') || 'Search Patients'}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleInputSearch()
                  }
                }}
                onChange={(e) => {
                  trimValue(e)
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                  if (searchValue !== '' && e.target.value === '') {
                    setActivateSmartSearch(false)
                    setGlobalFilter('')
                    const requestData = {
                      type: message,
                      search: e.target.value,
                      page: 1,
                      pageSize: dataPerPage,
                    }
                    dispatch(
                      getSettledInvoiceList(requestGenerator(requestData))
                    ).then((result) => setTotalPage(result.payload.lastPage))
                  }
                }}
              />

              <SearchButton
                handleClick={() => {
                  if (!!searchValue) {
                    handleInputSearch()
                  }
                }}
                customClass={styles.inputSearchButton}
              />
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '18px',
              }}
            >
              <input
                type="text"
                className={
                  !activateSmartSearch
                    ? styles.inputSmartSearchContainer
                    : styles.inputSearchContainer
                }
                placeholder={t('Common.Smart Search') || 'Smart Search'}
                disabled={!activateSmartSearch}
                onChange={(e) => {
                  trimValue(e)
                  setGlobalFilter(e.target.value)
                }}
                value={searchValue === '' ? searchValue : globalFilter}
              />
            </div>
          </div>

          <Divider customClass={styles.dividerStyling} />
          <div className={styles.tableContainer}>
            <TableV3
              handleRowClick={(item: any) =>
                handleRowClick ? handleRowClick(item) : handleView(item)
              }
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              setModelOpenClose={setModelOpenClose}
            />
          </div>

          {settledInvoiceList?.length < 9 &&
          settledInvoiceListObject?.lastPage === 1 &&
          settledInvoiceListObject?.nextPage === 0 &&
          settledInvoiceListObject?.previousPage === 0 ? (
            ' '
          ) : (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default SettledInvoiceModal
