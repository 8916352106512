import { t } from 'i18next'
import PatientEmrImportModule from './forms/PatientEmrImportModule'
import TreatmentServiceImport from './forms/TreatmentServiceImport'
import ImportInventoryItemForm from './forms/ImportInventoryItemForm'
import { CopyIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { hanleCopy } from '../../utils/utils'
import styles from './importUtility.module.scss'
import ImportAppointment from './forms/ImportAppointment'
import moment from 'moment'
import ReceiptUtility from './forms/ReceiptUtility'
import InvoiceUtility from './forms/InvoiceUtility'

export const importTypeOptions = [
  {
    label: 'Patient EMR',
    value: 'PATIENT_EMR',
    isDisabled: false,
  },
  {
    label: 'Treatment Services',
    value: 'TREATMENT_SERVICES',
    isDisabled: false,
  },
  {
    label: 'Inventory Items',
    value: 'INV_ITEMS',
    isDisabled: false,
  },
  {
    label: 'Appointments',
    value: 'APPOINTMENTS',
    isDisabled: false,
  },
  {
    label: 'Invoices',
    value: 'INVOICES',
    isDisabled: false,
  },
  {
    label: 'Receipt',
    value: 'RECEIPT',
    isDisabled: false,
  },
  {
    label: 'Treatment Plan',
    value: 'TREATMENT_PLAN',
    isDisabled: true,
  },
]

export const inventoryTypeOptions = [
  { label: 'GENERAL', value: 'GENERAL' },
  { label: 'MEDICINES', value: 'MEDICINES' },
]

export const PatientEmrCSVData = [
  {
    emr_no: 'EMR00001',
    national_id: '17126481321',
    national_id_type: 'CIVIL_ID/PASSPORT_ID',
    nationality: 'Kuwaiti',
    name: 'Taymullah Yusuf Assaf',
    dob: '1990-07-10',
    gender: 'MALE/FEMALE',
    phone: '+9650402515341',
    phone_2: '+9650402515341',
    blood_group: 'B+/B-/AB+/AB-/A+/A-/O-/O+/C+',
    email: 'taymullah@yopmail.com',
    marital_status: 'Single/Married/Widowed/Divorced/Separated',
    address: 'Honkajärventie',
    area: 'Kuwait',
    zip_code: '30300',
    allergies: 'Anaphylaxis',
    relative_name: 'Khaldoon Ata Antar',
    relative_phone: '+9650402515341',
    relative_relation: 'Brother',
    source: 'Instagram/LinkedIn/Facebook/Twitter',
  },
]
export const TreatmentServiceData = [
  {
    name: 'First aid',
    price: '100',
    cost: '90',
    user_code: '68274234',
    service_type: 'individual/group',
    sessions: '5',
    session_time: '120',
    // discount: '10',
    // notes: 'notes',
  },
]
export const AppointmentCSVData = [
  {
    emr_no: 'er000062',
    doctor_name: 'XXXXX',
    date: '2024-07-27',
    time: '18:00',
    duration: 30,
    status: 'Scheduled',
    patient_name: 'HANAN SABAH KADHEM',
    phone: '55996598',
    room: 'P1',
    remarks: 'no answer',
  },
]
export const generalInventoryItems = [
  {
    name: 'Blood test kits',
    unit_type_id: '64f033a7f9cd64565fef485a',
    group: 'individual/group',
    item_code: 'BLT10001',
    cost_price: 250,
    sell_price: 300,
    expiry_days: 365,
    type: 'GENERAL',
  },
]
export const medicineInventoryItems = [
  {
    name: 'Tetracyclines',
    unit_type_id: '64f033a7f9cd64565fef485a',
    group: 'individual/group',
    item_code: 'BLT10001',
    cost_price: 78,
    sell_price: 100,
    type: 'MEDICINES',
    medicines_compositions: 'Clarithromycin(1% w/w)',
    category: 'Tranquillizer',
    drug_class: 'Clarithromycin(1% w/w)',
    brand: 'Lupin Pvt Ltd',
  },
]

export const ReceiptCSVData = [
  {
    emr_no: 'GR000100',
    advance_amount: '1',
  },
]

export const InvoiceCSVData = [
  {
    Invoice_No: 'BPO1',
    EMR_No: 'PO1',
    Doctor_Name: 'HAMAD AL OTHMAN',
    Total_Amt: 45,
    Disc_Amt: 5,
    Net_Amt: 40,
    Date: '26-11-2023',
    branchkey: 'PO',
    SERVICES: 'Shockwave Therapy',
    Service_Price: 45,
    Service_Disc: 0,
    Service_Net: 45,
  },
]

export const csvOptions: any = {
  PATIENT_EMR: PatientEmrCSVData,
  TREATMENT_SERVICES: TreatmentServiceData,
  APPOINTMENTS: AppointmentCSVData,
  INVOICES: InvoiceCSVData,
  RECEIPT: ReceiptCSVData,
  TREATMENT_PLAN: PatientEmrCSVData,
}

export const csvINVOptions: any = {
  GENERAL: generalInventoryItems,
  MEDICINES: medicineInventoryItems,
}

export const importForm: any = {
  PATIENT_EMR: <PatientEmrImportModule />,
  TREATMENT_SERVICES: <TreatmentServiceImport />,
  INV_ITEMS: <ImportInventoryItemForm />,
  APPOINTMENTS: <ImportAppointment />,
  INVOICES: <InvoiceUtility />,
  RECEIPT: <ReceiptUtility />,
  TREATMENT_PLAN: <PatientEmrImportModule />,
}

export const inValidTableHeader = [
  {
    Header: 'INDEX',
    accessor: 'index',
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'MESSAGE',
    accessor: 'message',
  },
]
export const inAptValidTableHeader = [
  {
    Header: 'INDEX',
    accessor: 'index',
  },
  {
    Header: 'EMR NO',
    accessor: 'emr_no',
  },
  {
    Header: 'DOCTOR',
    accessor: 'doctor_name',
  },
  {
    Header: 'DATE',
    accessor: 'date',
    Cell: ({ row }: any) => {
      const date = row?.original?.date
      let appointment_date = moment(date)
      if (!appointment_date.isValid()) {
        appointment_date = moment(`${date}`, 'DD-MM-YYYY')
      }
      return <>{appointment_date.format('DD-MM-YYYY')}</>
    },
  },
  {
    Header: 'MESSAGE',
    accessor: 'message',
  },
]
export const inReceiptValidTableHeader = [
  {
    Header: 'INDEX',
    accessor: 'index',
  },
  {
    Header: 'EMR NO',
    accessor: 'emr_no',
  },

  {
    Header: 'MESSAGE',
    accessor: 'message',
  },
]

export const unitTypeHeaderData = [
  {
    Header: 'NAME',
    accessor: 'value',
  },
  {
    Header: 'ID',
    accessor: '_id',
    Cell: ({ row }: any) => {
      return (
        <>
          <div>
            <span>{row?.original?._id}</span>
            <CopyIcon
              fillColor={colors.grey2}
              handleClick={() => {
                hanleCopy(row?.original?._id, t('QrCode.Copied'))
              }}
              width={18}
              height={18}
              customClass={styles.copyIcon}
            />
          </div>
        </>
      )
    },
  },
]

export const inValidaHeaderData: any = {
  PATIENT_EMR: inValidTableHeader,
  TREATMENT_SERVICES: inValidTableHeader,
  INV_ITEMS: inValidTableHeader,
  APPOINTMENTS: inAptValidTableHeader,
  INVOICES: inValidTableHeader,
  RECEIPT: inReceiptValidTableHeader,
  TREATMENT_PLAN: inValidTableHeader,
}
