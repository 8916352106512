import { EditIcon } from '../../components/common/svg-components/index'
export const reportCategory = [
  {
    label: 'Doctor',
    value: 'doctor',
    reportType: [
      {
        label: 'Dept report',
        value: 'DEPT REPORT',
      },
      {
        label: 'Doctor report',
        value: 'Doctor REPORT',
      },
    ],
  },
  {
    label: 'Patient',
    value: 'patient',
    reportType: [
      {
        label: 'Patient-source',
        value: 'Patient-source',
      },
      {
        label: 'Patient-outstanding',
        value: 'patient-outstanding',
      },
    ],
  },
  {
    label: 'Sales',
    value: 'sales',
    reportType: [
      {
        label: 'Card-summary',
        value: 'card-summary',
      },
    ],
  },
  // {
  //   label: "Appointnment",
  //   value: "appointnment",
  //   reportType: [],
  // },
  // {
  //   label: "Inventory",
  //   value: "inventory",
  //   reportType: [],
  // },
  // {
  //   label: "Room",
  //   value: "room",
  //   reportType: [],
  // },
  // {
  //   label: "Insurance",
  //   value: "insurance",
  //   reportType: [],
  // },
]

export const cards = [
  {
    label: 'Select All',
    value: '',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Credit',
    value: 'credit',
  },
  {
    label: 'Debit',
    value: 'debit',
  },
  {
    label: 'Knet',
    value: 'knet',
  },
  {
    label: 'Loyalty Points',
    value: 'loyalty_points',
  },
  // {
  //   label: 'Pay With Advance',
  //   value: 'pay_with_advance',
  // },
]

export const reportType = [
  {
    label: 'Dept report',
    value: 'DEPT REPORT',
  },
  {
    label: 'Doctor report',
    value: 'Doctor REPORT',
  },
  {
    label: 'Patient-source',
    value: 'Patient-source',
  },
  {
    label: 'Patient-outstanding',
    value: 'patient-outstanding',
  },
  {
    label: 'Card-summary',
    value: 'card-summary',
  },
  {
    label: 'Sales Summary',
    value: 'sales-summary',
  },
  {
    label: 'Insurance Invoice Summary',
    value: 'insurance-invoice-summary',
  },
  {
    label: 'Patient List',
    value: 'patient-list',
  },
  {
    label: 'Invoice Wise Sales',
    value: 'invoice-wise-sales',
  },
  {
    label: 'Service Wise Sales Summary',
    value: 'service-wise-sales-summary',
  },
  {
    label: 'Service Wise Sales',
    value: 'service-wise-sales',
  },
  {
    label: 'Patient Statement',
    value: 'patient-statement',
  },
  {
    label: 'Day Wise Collection Summary',
    value: 'day-wise-collection-summary',
  },
  {
    label: 'Collection Summary',
    value: 'collection-summary',
  },
  {
    label: 'User Wise Collection Summary',
    value: 'user-wise-collection-summary',
  },
]

export const typeOfReport = [
  {
    label: 'Pre Build Report',
    value: 'PRE_BUILD_REPORT',
  },
  {
    label: 'Dynamic Report',
    value: 'DYNAMIC_REPORT',
  },
]

export const docTypes = ['pdf', 'txt', 'plain']
export const imgTypes = ['png', 'jpeg', 'jpg']

export const trialTetsdata = [
  {
    id: 2,
    name: 'liabilities',
    level_2: [
      {
        account_code: 201,
        name: 'current_libilities',
        voucher: [
          {
            account_code: 20101,
            name: 'al-sayafi company',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20102,
            name: 'Advance technology company KFMC',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20103,
            name: 'Noon medical company',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
      {
        account_code: 202,
        name: 'Test Voucher Liabilities',
        voucher: [
          {
            account_code: 20201,
            name: 'DISCOUNT_DIAGNOSIS',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20202,
            name: 'DISCOUNT_DENTAL_DIAGNOSIS',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 20203,
            name: 'DISCOUNT_IPD',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Assets',
    level_2: [
      {
        account_code: 301,
        name: 'FIXED ASSETS',
        voucher: [
          {
            account_code: 30101,
            name: 'Equipment & devices',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 30102,
            name: 'New Test',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
      {
        account_code: 302,
        name: 'CURRENT ASSETS',
        voucher: [
          {
            account_code: 30201,
            name: 'KFMC XXXXXXXXXX8836',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
          {
            account_code: 30201,
            name: 'KFMC XXXXX1010001456',
            credit: 100,
            debit: 100,
            closing_credit: 100,
            closing_debit: 0,
            opening_credit: 100,
            opening_debit: 0,
          },
        ],
      },
    ],
  },
]
