import { FC, useEffect } from 'react'
import styles from './ViewReceipt.module.scss'
import TableV3 from '../../table/tableV3/TableV3'
import Divider from '../../divider/Divider'
import { SearchButton } from '../../svg-components'
import { trimValue } from '../../../../utils/utils'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../../interfaces/interfaces'
import SmartSearch from '../../smart-search/SmartSearch'
import Loader from '../../spinner/Loader'
import { useTranslation } from 'react-i18next'
import { getAllTemplates } from '../../../../redux/features/dynamic-template/dynamicTemplateAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { GET_TEMPLATE_TYPE } from '../../../../constants/asyncActionsType'
import { useAppDispatch, useAppSelector } from '../../../../hooks'

interface IViewReceiptModal {
  rowData?: any
  datas?: any
  searchValue?: any
  setSearchValue?: any
  handleSearch?: any
  children?: any
  activateSmartSearch?: any
  setshowSettleEmailPopup?: any
  setpatientReceiptData?: any
  setTemplateOptions: any
}

const ViewReceipt: FC<IViewReceiptModal> = ({
  rowData,
  datas,
  searchValue,
  setSearchValue,
  handleSearch,
  children,
  activateSmartSearch,
  setshowSettleEmailPopup,
  setpatientReceiptData,
  setTemplateOptions,
}) => {
  const { isLoading } = useAppSelector((state) => state.template)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const data: Cols[] = datas ?? []
  const columns: Column<Cols>[] = rowData ?? []
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state
  //dynamic template
  useEffect(() => {
    dispatch(
      getAllTemplates(
        requestGenerator({ type: 'receipt', page: 1, pageSize: 1000 })
      )
    ).then((e) => {
      if (e.type === `${GET_TEMPLATE_TYPE}/fulfilled`) {
        const options = e.payload?.data?.map((item: any) => {
          return {
            label: item?.name,
            value: item?._id,
          }
        })
        setTemplateOptions([{ label: 'Default', value: 'default' }, ...options])
      }
    })
  }, [dispatch, setTemplateOptions])
  return (
    <>
      {isLoading ? <Loader /> : ''}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.searchFieldContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '25px',
              width: '620px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder={t('Common.Search Patients') || 'Search Patients'}
                onChange={(e) => {
                  trimValue(e)
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                }}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />

              <SearchButton
                handleClick={() => {
                  if (!!searchValue) {
                    handleSearch()
                  }
                }}
                customClass={styles.inputSearchButton}
              />
            </div>

            <SmartSearch
              placeHolder={t('Common.Smart Search') || 'Smart Search'}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDisable={!activateSmartSearch}
            />
          </div>

          <Divider customClass={styles.dividerStyling} />
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              handleRowClick={(item: any) => {
                setshowSettleEmailPopup(true)
                setpatientReceiptData({
                  _id: item?._id,
                  patient_email: item?.patient_detail[0]?.user?.email || '',
                  showEmail: item?.showEmail,
                })
              }}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>

          {children}
        </div>
      </div>
    </>
  )
}

export default ViewReceipt
