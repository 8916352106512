import { FC } from "react";
import styles from "./style.module.scss";

interface IErrorProps {
  message: string;
}

export const ErrorMessage: FC<IErrorProps> = (props) => {
  const { message } = props;
  return (
    <div className={styles.errorContainer} >
      <span className={styles.extraSpan} />
      <p className="dashboardFormError">{message}</p>
    </div>
  );
};
