import { t } from 'i18next'
import {
  UncheckIcon,
  CheckIcon,
} from '../../components/common/svg-components/index'
import { colors } from '../../constants/color'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  setNormalFields,
  setTableFields,
} from '../../redux/features/dynamic-template/dynamicTemplateSlice'

export const TEMP_NAME = 'name'
export const TEMP_TYPE = 'type'
export const HEADER = 'header_content'
export const LOWER = 'lower_content'
export const NORMAL_FIELD = 'normal_field'
export const TABLE_FIELD = 'table_field'

export const templateTypes = [
  {
    label: 'Invoice',
    value: 'invoice',
  },
  {
    label: 'Receipt',
    value: 'receipt',
  },
]

export const invoiceNormalField: { name: string; check: boolean }[] = [
  {
    name: 'patient_name',
    check: true,
  },
  {
    name: 'emr_no',
    check: true,
  },
  {
    name: 'invoice_no',
    check: true,
  },
  {
    name: 'invoice_date',
    check: true,
  },
  {
    name: 'doctor_name',
    check: true,
  },
  {
    name: 'insurance_company_name',
    check: true,
  },
  {
    name: 'insurance_claim_amount',
    check: true,
  },
  {
    name: 'total_amount',
    check: true,
  },
  {
    name: 'gross_amount',
    check: true,
  },
  {
    name: 'discount',
    check: true,
  },
  {
    name: 'net_amount',
    check: true,
  },
  {
    name: 'paid_amount',
    check: true,
  },
  {
    name: 'due_amount',
    check: true,
  },
  {
    name: 'phone',
    check: true,
  },
  {
    name: 'email',
    check: true,
  },
]

export const invoiceTableField: { name: string; check: boolean }[] = [
  {
    name: 'doctor_name',
    check: true,
  },
  {
    name: 'unitPrice',
    check: true,
  },
  {
    name: 'quantity',
    check: true,
  },
  {
    name: 'discount',
    check: true,
  },
  {
    name: 'item_name',
    check: true,
  },
  {
    name: 'total_price',
    check: true,
  },
  {
    name: 'cost_price',
    check: true,
  },
  {
    name: 'session',
    check: true,
  },
  {
    name: 'test_no',
    check: true,
  },
  {
    name: 'copay_price',
    check: true,
  },
  {
    name: 'refund amount',
    check: true,
  },
]

export const receiptNormalField = [
  {
    name: 'receipt_no',
    check: true,
  },
  {
    name: 'receipt_date',
    check: true,
  },
  {
    name: 'file_no',
    check: true,
  },
  {
    name: 'patient_name',
    check: true,
  },
  {
    name: 'patient_email',
    check: true,
  },
  {
    name: 'patient_phone',
    check: true,
  },
  {
    name: 'total_amount',
    check: true,
  },
  {
    name: 'amount_word',
    check: true,
  },
  {
    name: 'total_invoice_amount',
    check: true,
  },
  {
    name: 'total_pre_paid',
    check: true,
  },
  {
    name: 'total_invoice_sum',
    check: true,
  },
  {
    name: 'total_due_amount',
    check: true,
  },
]

export const receiptTableField = [
  {
    name: 'sr_no',
    check: true,
  },
  {
    name: 'invoice_no',
    check: true,
  },
  {
    name: 'invoice_amount',
    check: true,
  },
  {
    name: 'pre_paid',
    check: true,
  },
  {
    name: 'invoice_sum',
    check: true,
  },
  {
    name: 'due_amount',
    check: true,
  },
]

export const tablefieldHeaderData = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('AllAttachmentHeader.SELECT'),
    accessor: 'check',
    Cell: ({ row }: any) => {
      const isChecked = row?.original?.check
      const { tableField } = useAppSelector((state) => state.template)
      const dispatch = useAppDispatch()
      const handleCheck = (check: boolean) => {
        let tempArr: any[] = []
        tempArr = tableField?.map((item: any) => {
          if (item?.name === row?.original?.name) {
            return {
              ...item,
              check: check,
            }
          } else {
            return item
          }
        })
        dispatch(setTableFields(tempArr))
      }
      return isChecked ? (
        <CheckIcon
          fillColor={colors.green1}
          handleClick={() => handleCheck(false)}
        />
      ) : (
        <UncheckIcon
          fillColor={colors.grey1}
          handleClick={() => handleCheck(true)}
        />
      )
    },
  },
]
export const normalfieldHeaderData = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('AllAttachmentHeader.SELECT'),
    accessor: 'check',
    Cell: ({ row }: any) => {
      const isChecked = row?.original?.check
      const { normalField } = useAppSelector((state) => state.template)
      const dispatch = useAppDispatch()
      const handleCheck = (check: boolean) => {
        let tempArr: any[] = []
        tempArr = normalField?.map((item: any) => {
          if (item?.name === row?.original?.name) {
            return {
              ...item,
              check: check,
            }
          } else {
            return item
          }
        })
        dispatch(setNormalFields(tempArr))
      }
      return isChecked ? (
        <CheckIcon
          fillColor={colors.green1}
          handleClick={() => handleCheck(false)}
        />
      ) : (
        <UncheckIcon
          fillColor={colors.grey1}
          handleClick={() => handleCheck(true)}
        />
      )
    },
  },
]

export const matchNormalFields: any = {
  invoice: invoiceNormalField,
  receipt: receiptNormalField,
}

export const matchTableField: any = {
  invoice: invoiceTableField,
  receipt: receiptTableField,
}

export const templateTableHeaderData = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
]
