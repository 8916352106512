import { FC, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { ongoingClaimsHeaderMatch } from './onGoingClaimsTableData'
import { CustomRadio } from '../../../components/common/custom-radio'
import Popup from '../../../components/common/popup/Popup'
import AddClaimsPopup from './add-claim-popup/addClaim'
import SettledClaimPopup from './settled-claims-popup/settledClaim'
import RejectedClaimPopup from './rejected-claims-popup/rejectedClaim'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  createNewClaim,
  createSetteledClaims,
  getAllClaimData,
  getAllOngoingClaims,
  getPandingClaims,
} from '../../../redux/features/ongoing-claims/onGoingClaimsAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { IAddClaimForm } from '../../../interfaces/interfaces'
import { MARKETPLACE_ADD_CLAIM } from './add-claim-popup/addClaimConstants'
import { SearchButton } from '../../../components/common/svg-components'
import { reactSelectStyle } from '../../../constants/data'
import makeAnimated from 'react-select/animated'
import { getAllmarketplace } from '../../../redux/features/insurance/insuranceAsyncActions'
import moment from 'moment'
import AddNotesPopup from './add-notes-popup/AddNotesPopup'
import { CREATE_CLAIM_TYPE } from '../../../constants/asyncActionsType'
import { handlePadStart } from '../../../utils/utils'
import { useTranslation } from 'react-i18next'

interface ICreateJobs {}

const OnGoingClaims: FC<ICreateJobs> = () => {
  const { t } = useTranslation()
  const [claimsPopup, setClaimsPopup] = useState<boolean>(false)
  const [settledClaim, setSettledClaim] = useState<boolean>(false)
  const [rejectedClaim, setRejectedClaim] = useState<boolean>(false)
  const [check, setCheck] = useState('INITIATED')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const [totalData, setTotalData] = useState(0)
  const [active, setActive] = useState<any>('')
  const [selectedClaim, setSelectedClaim] = useState<any>({})
  const [dateFilter, setDateFilter] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  })
  const [marketPlaceOptions, setMarketOptions] = useState<any>([])
  const [maxEndDate, setMaxEndDate] = useState<any>('')
  const animatedComponent = makeAnimated()
  const { marketplaceData } = useAppSelector((state) => state.insurance)
  const { pandingClaimsData, initiatedClaimsData } = useAppSelector(
    (state) => state.ongoingClaims
  )
  const [showNotes, setShowNotes] = useState(false)
  const [notesData, setNotesData] = useState('')
  const [claimData, setClaimData] = useState(pandingClaimsData)
  const [focusedField, setFocusedField] = useState('')
  const [focusRejectedField, setFocusRejectedField] = useState('')
  const [error, setError] = useState(false)
  const [uId, setUId] = useState('')
  const [recentNotes, setRecentNotes] = useState('')

  const handleFocus = (_id: any) => {
    setFocusedField(_id)
    setFocusRejectedField('')
  }

  const handleRejectedFocus = (_id: any) => {
    setFocusedField('')
    setFocusRejectedField(_id)
  }

  useEffect(() => {
    setClaimData(initiatedClaimsData)
  }, [initiatedClaimsData])

  useEffect(() => {
    dispatch(getAllmarketplace(requestGenerator({ page: 1, pageSize: 1000 })))
  }, [])

  useEffect(() => {
    if (marketplaceData && marketplaceData.length > 0) {
      // set market options
      const value = marketplaceData?.map((item: any) => ({
        label: item?.marketplace_name,
        value: item?._id,
      }))
      setMarketOptions(value)
    }
  }, [marketplaceData])

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const dispatch = useAppDispatch()
  const { isLoading, onGoingClaimsData } = useAppSelector(
    (state) => state.ongoingClaims
  )

  // New ongoing claims development

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IAddClaimForm>({})
  const fromData = watch()

  const getPendigClaimData = (page?: number) => {
    setRecentNotes('')
    setSelectedClaim({})
    setError(false)
    setActive('')
    let reqData = {
      marketplace_id: fromData[MARKETPLACE_ADD_CLAIM]?.value,
      page: page ? page : pageIndex,
      pageSize: dataPerPage,
      startDate: dateFilter?.from ? dateFilter?.from : undefined,
      endDate: dateFilter?.to ? dateFilter?.to : undefined,
    }

    if (
      (dateFilter?.to === '' && dateFilter?.from === '') ||
      (dateFilter?.to !== '' && dateFilter?.from !== '')
    ) {
      dispatch(getPandingClaims(requestGenerator(reqData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
        }
      )
    }
  }

  useEffect(() => {
    if (fromData[MARKETPLACE_ADD_CLAIM]?.value !== undefined) {
      getPendigClaimData()
    }
  }, [pageIndex, dataPerPage])

  useEffect(() => {
    let reqData = {
      marketplace_id: fromData[MARKETPLACE_ADD_CLAIM]?.value,
      page: pageIndex,
      pageSize: dataPerPage,
      startDate: dateFilter?.from ? dateFilter?.from : undefined,
      endDate: dateFilter?.to ? dateFilter?.to : undefined,
      claim_status: check,
    }
    if (fromData[MARKETPLACE_ADD_CLAIM]?.value !== undefined) {
      if (check === 'INITIATED') {
        getPendigClaimData()
      } else {
        dispatch(getAllClaimData(requestGenerator(reqData))).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    }
  }, [dispatch, pageIndex, dataPerPage, check])

  const getAllClaimDataStatus = () => {
    let reqData = {
      marketplace_id: fromData[MARKETPLACE_ADD_CLAIM]?.value,
      page: pageIndex,
      pageSize: dataPerPage,
      startDate: dateFilter?.from ? dateFilter?.from : undefined,
      endDate: dateFilter?.to ? dateFilter?.to : undefined,
      claim_status: check,
    }
    if (fromData[MARKETPLACE_ADD_CLAIM]?.value !== undefined) {
      if (check === 'INITIATED') {
        getPendigClaimData()
      } else {
        dispatch(getAllClaimData(requestGenerator(reqData))).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    }
  }

  const handleSettedAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    _id: any
  ) => {
    let newId = initiatedClaimsData?.length > 0 ? _id : parseInt(_id)
    let newData = claimData?.map((item: any) => {
      if (item?._id == newId) {
        let updateData = {
          ...item,
          settled_amount: e.target.value,
        }
        return updateData
      } else {
        return item
      }
    })

    setClaimData(newData)
  }

  const handleRejectedAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    _id: any
  ) => {
    let newId = initiatedClaimsData?.length > 0 ? _id : parseInt(_id)
    let newData = claimData?.map((item: any) => {
      if (item?._id == newId) {
        let updateData = {
          ...item,
          rejected_amount: e.target.value,
        }
        return updateData
      } else {
        return item
      }
    })

    setClaimData(newData)
  }

  const blockInvalidChar = (e: any) =>
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

  const ongoingClaimsHeaderDataIntiated: any = [
    {
      Header: t('RcptTableHeader.DATE'),
      accessor: '_date',
      Cell: (props: any) => {
        return (
          <>{moment(props?.row?.original?.createdAt).format('DD MMM yyyy')}</>
        )
      },
    },
    {
      Header: t('OngoingClaims.INSURANCE COMPANY'),
      accessor: 'insurace_company_name',
    },
    {
      Header: t('PatientHistoryFormHeader.PATIENT NAME'),
      accessor: 'patient_name',
    },
    {
      Header: t('InsuranceHeader.MEMBERSHIP NO'),
      accessor: 'policy_no',
      Cell: (props: any) => {
        return (
          <p>
            {props?.row?.original?.policy_no
              ? props?.row?.original?.policy_no
              : '-'}
          </p>
        )
      },
    },
    {
      Header: t('SettledInvoice.INVOICE NO'),
      accessor: 'invoice_no',
      Cell: (props: any) => {
        return <>{handlePadStart(props?.row?.original?.invoice_no)}</>
      },
    },
    {
      Header: t('OngoingClaims.CLAIM AMOUNT'),
      accessor: 'claim_amount',
      Cell: (props: any) => {
        return (
          <>
            {props.row.original?.claim_amount
              ? `$ ${props.row.original?.claim_amount}`
              : '0'}
          </>
        )
      },
    },
    {
      Header: t('OngoingClaims.SETTLED AMOUNT'),
      accessor: 'settled_amount',
      Cell: (props: any) => {
        const _id = props?.row?.original?._id
        const handleChange = () => {
          if (
            props.row.original?.claim_amount <
            parseFloat(props.row.original?.settled_amount) +
              parseFloat(props.row.original?.rejected_amount)
          ) {
            setError(true)
          } else if (props?.row?.original?.settled_amount < 0) {
            setError(true)
          } else {
            setError(false)
          }
        }
        return (
          <>
            <input
              className={styles.tableInput}
              value={props?.row?.original?.settled_amount}
              onChange={(e) => {
                handleSettedAmount(e, _id)
                handleChange()
              }}
              onFocus={() => handleFocus(_id)}
              onKeyDown={(e) => blockInvalidChar(e)}
              key={_id}
              autoFocus={focusedField === _id}
              type="number"
              disabled={check === 'COMPLETED' ? true : false}
            />
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount === '' &&
              props?.row?.original?.settled_amount === '' && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterSettled')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount === 0 &&
              props?.row?.original?.settled_amount === 0 && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterSettled')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount === 0 &&
              props?.row?.original?.settled_amount === '' && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterSettled')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.settled_amount < 0 && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterPositive')}
                </p>
              )}
            {_id === uId &&
              error &&
              parseFloat(props?.row?.original?.settled_amount) !== 0 &&
              parseFloat(props?.row?.original?.settled_amount) >
                props.row.original?.claim_amount -
                  parseFloat(props.row.original?.rejected_amount) && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterLess')}
                </p>
              )}
          </>
        )
      },
    },
    {
      Header: t('OngoingClaims.REJECTED AMOUNT'),
      accessor: 'rejected_amount',
      Cell: (props: any) => {
        const _id = props?.row?.original?._id

        const handleChange = (e: any) => {
          if (
            props.row.original?.claim_amount <
            parseFloat(props.row.original?.settled_amount) +
              parseFloat(props.row.original?.rejected_amount)
          ) {
            setError(true)
          } else if (props?.row?.original?.rejected_amount < 0) {
            setError(true)
          } else {
            setError(false)
          }
        }
        return (
          <>
            <input
              className={styles.tableInput}
              value={props?.row?.original?.rejected_amount}
              onChange={(e) => {
                handleRejectedAmount(e, _id)
                handleChange(e)
              }}
              onFocus={() => handleRejectedFocus(_id)}
              onKeyDown={(e) => blockInvalidChar(e)}
              key={_id}
              autoFocus={focusRejectedField === _id}
              type="number"
              disabled={check === 'COMPLETED' ? true : false}
            />
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount === '' &&
              props.row.original?.settled_amount === '' && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterReject')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount === 0 &&
              props?.row?.original?.settled_amount === 0 && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterReject')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.settled_amount === 0 &&
              props?.row?.original?.rejected_amount === '' && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterReject')}
                </p>
              )}
            {_id === uId &&
              error &&
              props?.row?.original?.rejected_amount < 0 && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterPositive')}
                </p>
              )}
            {_id === uId &&
              error &&
              parseFloat(props?.row?.original?.rejected_amount) !== 0 &&
              parseFloat(props?.row?.original?.rejected_amount) >
                props.row.original?.claim_amount -
                  parseFloat(props.row.original?.settled_amount) && (
                <p className="errorText" style={{ textAlign: 'center' }}>
                  {t('OngoingClaims.EnterLess')}
                </p>
              )}
          </>
        )
      },
    },
    {
      Header: t('PatientHistoryTableHeader.NOTES'),
      accessor: 'notes',
      Cell: (props: any) => {
        const handleNotes = (id: any) => {
          setUId(id)
          if (check === 'COMPLETED' && props.row.original?.notes?.length > 0) {
            setShowNotes(true)
            setNotesData(props.row.original?.notes)
          } else if (check !== 'COMPLETED') {
            setShowNotes(true)
            if (props?.row?.original?._id === uId && recentNotes?.length > 0) {
              setNotesData(recentNotes)
            } else {
              setNotesData(props.row.original?.notes)
            }
          } else {
            setShowNotes(false)
            setNotesData('')
            setRecentNotes('')
          }
        }

        return (
          <>
            <span
              className={styles.view}
              onClick={() => handleNotes(props?.row?.original?._id)}
            >
              {check === 'COMPLETED'
                ? props.row.original?.notes?.length > 0
                  ? t('Common.View')
                  : '-'
                : props.row.original?.notes?.length > 0
                ? t('Common.View')
                : t('InvoiceService.Add')}
            </span>
          </>
        )
      },
    },
    {
      Header: t('DocUploadHeader.ACTION'),
      accessor: 'action',
      Cell: (props: any) => {
        const _id = props?.row?.original?._id

        const handleSave = (_id: any) => {
          setUId(_id)
          if (_id === props?.row?.original?._id) {
            let payloadData = {
              settled_amount:
                props.row.original?.settled_amount === ''
                  ? 0
                  : parseFloat(props.row.original?.settled_amount),
              rejected_amount:
                props.row.original?.rejected_amount === ''
                  ? 0
                  : parseFloat(props.row.original?.rejected_amount),
              notes: notesData,
              invoice_id: props.row.original?.invoice_id,
              date_initiated: new Date().toISOString().split('T')[0],
            }
            if (
              props.row.original?.settled_amount === 0 &&
              props.row.original?.rejected_amount === 0
            ) {
              setError(true)
            } else if (
              props.row.original?.settled_amount === null ||
              props.row.original?.rejected_amount === null
            ) {
              setError(true)
            } else if (
              props.row.original?.settled_amount === '' ||
              props.row.original?.rejected_amount === ''
            ) {
              setError(true)
            } else if (
              props.row.original?.claim_amount <
              parseFloat(props.row.original?.settled_amount) +
                parseFloat(props.row.original?.rejected_amount)
            ) {
              setError(true)
            } else if (
              props.row.original?.settled_amount < 0 ||
              props.row.original?.rejected_amount < 0
            ) {
              setError(true)
            } else {
              setError(false)
              if (check === 'INITIATED') {
                dispatch(createNewClaim(requestGenerator(payloadData))).then(
                  (e) => {
                    if (e.type === `${CREATE_CLAIM_TYPE}/fulfilled`) {
                      setNotesData('')
                      setRecentNotes('')
                      getPendigClaimData()
                      setUId('')
                    }
                  }
                )
              } else if (check === 'PENDING') {
                let reqData = {
                  claim_id: props.row.original?._id,
                  data: {
                    settled_amount: payloadData.settled_amount,
                    rejected_amount: payloadData.rejected_amount,
                    notes: payloadData?.notes,
                  },
                  invoice_id: props.row.original?.invoice_id,
                }
                dispatch(createSetteledClaims(requestGenerator(reqData))).then(
                  (e) => {
                    if (
                      e?.type ===
                      'ongoing-claims/createSetteledClaims/fulfilled'
                    ) {
                      getAllClaimDataStatus()
                      setUId('')
                    }
                  }
                )
              }
            }
          }
        }
        return (
          <>
            <button
              className={styles.saveBtn}
              onClick={() => handleSave(_id)}
              disabled={check === 'COMPLETED' ? true : false}
              key={_id}
            >
              {t('Common.Save')}
            </button>
          </>
        )
      },
    },
  ]

  return (
    <>
      {isLoading && <Loader />}
      {showNotes && (
        <Popup
          Children={AddNotesPopup}
          handleClose={() => {
            setShowNotes(false)
          }}
          handleSubmitData={(data: any) => {
            setNotesData(data)
            setRecentNotes(data)
          }}
          popData={notesData}
        />
      )}
      {/* Add Test Popup */}
      {/* {claimsPopup && (
        <Popup
          Children={AddClaimsPopup}
          handleClose={() => setClaimsPopup(false)}
          handleYes={() => {
            handleReloadPage(setClaimsPopup)
          }}
          setModelOpenClose={setCheck}
          heading={check}
          setIsDefault={setTotalPage}
        />
      )}
      {settledClaim && (
        <Popup
          Children={SettledClaimPopup}
          handleClose={() => setSettledClaim(false)}
          handleYes={() => {
            handleReloadPage(setSettledClaim)
          }}
          setModelOpenClose={setCheck}
          heading={check}
          setIsDefault={setTotalPage}
        />
      )}
      {rejectedClaim && (
        <Popup
          Children={RejectedClaimPopup}
          handleClose={() => setRejectedClaim(false)}
          handleYes={() => {
            handleReloadPage(setRejectedClaim)
          }}
          setModelOpenClose={setCheck}
          heading={check}
          setIsDefault={setTotalPage}
        />
      )}
      {notePopup.open && (
        <Popup
          Children={DescriptionModal}
          handleClose={() => setNotePopup({ open: false, note: {} })}
          heading={'Notes'}
          popData={{ notes: notePopup.note?.noteDetail || '' }}
        />
      )} */}
      {/* Table Container*/}
      <div className={styles.pageWrapper}>
        <div className={styles.claims_Wrapper}>
          <h4 style={{ fontSize: '22px', fontWeight: '600' }}>
            {t('OngoingClaims.Ongoing Claims')}
          </h4>
          {/* <div className={styles.createJobBtnContainer}>
            <Button
              title="Add Claim"
              type="button"
              handleClick={() => setClaimsPopup(true)}
            />
            <Button
              title="Claim Status"
              type="button"
              handleClick={() => setSettledClaim(true)}
            />
          </div> */}
        </div>
        <div className={styles.searchFilterContainer}>
          <div className={styles.formWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={t('OngoingClaims.Market Place') || 'Market Place'}
              />
              <Select
                className={styles.selectField}
                isSearchable={true}
                options={marketPlaceOptions}
                value={watch(MARKETPLACE_ADD_CLAIM)}
                components={animatedComponent}
                closeMenuOnSelect={true}
                placeholder={
                  t('OngoingClaims.SelectMarketPlace') || 'Select Market Place'
                }
                {...register(MARKETPLACE_ADD_CLAIM)}
                onChange={(e: any) => {
                  setValue(MARKETPLACE_ADD_CLAIM, e)
                  trigger(MARKETPLACE_ADD_CLAIM)
                }}
                styles={reactSelectStyle}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div className={styles.dateRangeContainer}>
            <span className={styles.dateLabel}>{t('OngoingClaims.From')}</span>
            <div className={styles.dateFieldContainer}>
              <input
                type="date"
                value={dateFilter?.from}
                id="from-date"
                onChange={(e: any) => {
                  setDateFilter({ ...dateFilter, from: e?.target?.value })
                }}
                className={styles.dateField}
                max={
                  dateFilter?.to &&
                  (new Date(dateFilter?.to)?.toISOString().split('T')[0] ?? '')
                }
              />
              <p className="errorText">
                {dateFilter?.to !== '' &&
                  dateFilter?.from === '' &&
                  t('OngoingClaims.FromDate')}
              </p>
            </div>
            <span className={styles.dateLabel}>
              {t('ShareQuestionnaire.To')}
            </span>
            <div className={styles.dateFieldContainer}>
              <input
                type="date"
                value={dateFilter?.to}
                id="to-date"
                max="9999-12-31"
                min={
                  maxEndDate &&
                  (new Date(maxEndDate)?.toISOString()?.split('T')[0] ?? '')
                }
                onChange={(e: any) => {
                  setDateFilter({ ...dateFilter, to: e?.target?.value })
                }}
                className={styles.dateField}
              />
              <p className="errorText">
                {dateFilter?.from !== '' &&
                  dateFilter?.to === '' &&
                  t('OngoingClaims.ToDate')}
              </p>
            </div>
            <SearchButton
              handleClick={() => {
                setPageIndex(1)
                getPendigClaimData(1)
                setCheck('INITIATED')
              }}
            />
          </div>
        </div>
        <div className={styles.radioFieldContainer}>
          <CustomRadio
            value="INITIATED"
            name="task"
            label={t('OngoingClaims.Initiated') || 'Initiated'}
            checked={check === 'INITIATED'}
            onChange={() => setCheck('INITIATED')}
          />
          <CustomRadio
            value="PENDING"
            name="task"
            label={t('OngoingClaims.Pending') || 'Pending'}
            checked={check === 'PENDING'}
            onChange={() => setCheck('PENDING')}
          />
          <CustomRadio
            value="COMPLETED"
            name="task"
            label={t('OngoingClaims.Completed') || 'Completed'}
            checked={check === 'COMPLETED'}
            onChange={() => setCheck('COMPLETED')}
          />
        </div>
        <div className={styles.TableMainContainer}>
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={ongoingClaimsHeaderDataIntiated}
              tableRowData={claimData}
              active={false}
            />
          </div>
          {pandingClaimsData &&
            pandingClaimsData.length > 0 &&
            totalData > 10 && (
              <Pagination
                pageSize={dataPerPage}
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            )}
        </div>
      </div>
    </>
  )
}

export default OnGoingClaims
