/* eslint-disable no-lone-blocks */
import { IAPIResponse, IAPIPayload } from '../interfaces/apiInterface'
import { IToastType } from '../interfaces/interfaces'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  caladerDropdownData,
  failure,
  globalError,
  matchExclude,
  success,
  warning,
} from '../constants/data'
import {
  setMessage,
  setToolTipMessage,
} from '../redux/features/toast/toastSlice'
import moment from 'moment'
import { store } from '../redux/store/store'
import axios from 'axios'
import jsPDF from 'jspdf'

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v?.toString(16)
  })
}

export const handleRefClick = (ref: any) => {
  if (ref.current) {
    ref.current.click()
  }
}

export const getAPIResponse = (response: IAPIResponse): IAPIResponse => {
  const { dk, payloadResponse, rc } = response
  return { dk, payloadResponse, rc }
}

export const getToastPayload = (
  msg: string,
  type: IToastType,
  fieldMSG?: string
) => {
  const payload = {
    message: fieldMSG ? `${msg} - ${fieldMSG}` : msg,
    type: type,
  }
  return payload
}

//Funtion for Create Base64
const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    let baseURL: any = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

//Funtion for Create DataURI

export const dataURI = async (file: File) => {
  try {
    const result = await getBase64(file)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createAsyncThunkForSlice = (
  type: string,
  resolver: any,
  options?: { isEncrypt?: boolean; isToast?: boolean; extraParam?: boolean }
) =>
  createAsyncThunk(
    type,
    async (payload: IAPIPayload, { dispatch, rejectWithValue }) => {
      try {
        const res: any = await resolver(payload, options?.extraParam)
        if (res?.rc === 0) {
          // options?.isEncrypt && res?.dk && dispatch(setEncryptionKey(res?.dk)) // set static key for cryptography
          {
            options?.isToast &&
              dispatch(
                setMessage(
                  getToastPayload(res.payloadResponse.message, success)
                )
              )
          } // set toast notifaction msg for response
          return res.payloadResponse.data
        } else if (res?.payloadResponse?.sc === 401) {
          dispatch(
            setMessage({
              message: 'Your session has been expired, please login again.',
              type: failure,
            })
          )
          setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
          }, 1000)

          throw new Error(res.payloadResponse.error[0].message)
        } else {
          {
            options?.isToast &&
              dispatch(
                setMessage(
                  getToastPayload(
                    res.payloadResponse?.error[0].message ?? globalError,
                    failure
                  )
                )
              )
          } // set toast notifaction msg for response
          throw new Error(res.payloadResponse.error[0].message)
        }
      } catch (error: any) {
        {
          options?.isToast &&
            dispatch(
              setMessage(getToastPayload(error.message ?? globalError, failure))
            )
        }
        return rejectWithValue(error.message)
      }
    }
  )

export default createAsyncThunkForSlice

// Function UTC TO normal date Formate
export const utcToDate = (dateString: string, isForm?: boolean) => {
  const utcDate = new Date(dateString)
  // Convert UTC date to local date
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  )
  const day = localDate.getDate().toString().padStart(2, '0')
  // const month = day.toLocaleString();
  const month = (localDate.getMonth() + 1).toString().padStart(2, '0')
  const year = localDate.getFullYear().toString().substr(-4)
  const formattedDate = isForm
    ? `${year}-${month}-${day}`
    : `${day}-${month}-${year}`
  return formattedDate
}

// export const handleBirthDateChange = (ageDateString: string) => {
//   const birthDate = new Date(ageDateString);
//   const today = new Date();
//   const years = today.getFullYear() - birthDate.getFullYear();
//   const months = today.getMonth() - birthDate.getMonth();
//   const days = today.getDate() - birthDate.getDate();
//   let ageStr = "";
//   if (months < 0 || (months === 0 && days < 0)) {
//     ageStr = `${years - 1}yr  ${12 + months}m  ${30 + days}d`;
//   } else {
//     ageStr = `${years}yr  ${months}m  ${days}d`;
//   }
//   return ageStr;
// };

export const handleBirthDateChange = (selectedDate: any) => {
  const birthDate = new Date(selectedDate)
  const today = new Date()
  const years = today.getFullYear() - birthDate.getFullYear()
  const months = today.getMonth() - birthDate.getMonth()
  const days = today.getDate() - birthDate.getDate()
  let ageStr = ''
  if (months < 0 || (months === 0 && days < 0)) {
    ageStr = `${years - 1}yr  ${12 + months}m  ${30 + days}d`
  } else {
    ageStr = `${years}yr  ${months}m  ${days}d`
  }
  return ageStr
}

export const calculateAge = (selectedDate: any) => {
  let today = new Date()
  let dob = new Date(selectedDate)
  let diff = today.getTime() - dob.getTime()
  let years = Math.floor(diff / 31556736000)
  let daysDiff = Math.floor((diff % 31556736000) / 86400000)
  let months = Math.floor(daysDiff / 30.4167)
  let days = Math.floor(daysDiff % 30.4167)
  let ageStr = `${years}yr  ${months}m  ${days}d`
  return ageStr
}

export const trimValue = (e: any) => {
  let value = e.target.value
  if (value.length === 1 && value === ' ') {
    e.target.value = ''
  } else if (
    value.length > 1 &&
    value[0] === ' ' &&
    value[value.length - 1] === ' '
  ) {
    value = value.trim()
    const words = value.split(' ')
    const filteredWords = words.filter((word: any) => word !== '')
    e.target.value = filteredWords.join(' ')
  } else if (value.length > 1 && value[0] === ' ') {
    e.target.value = value.trim()
  }
}

// Disable up down arrow
export const disableArrowKey = (e: KeyboardEvent): void => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault()
  }
}

// Disable Scroll Event for input type number
export const disableScroll = () => {
  const input = document.querySelector('input[type=number]')
  const handleWheel = (event: any) => {
    if (input === document.activeElement) {
      event.preventDefault()
    }
  }
  input && input.addEventListener('wheel', handleWheel, { passive: false })
  return () => {
    input && input.removeEventListener('wheel', handleWheel)
  }
}

// Function for converting time to AM and PM format
export const convertTimeTo12HourFormat = (timeStr: any) => {
  const [hour, minute] = timeStr.split(':')
  const period = hour >= 12 ? 'PM' : 'AM'
  const hour12 = hour % 12 || 0
  return `${hour12}:${minute} ${period}`
}

export const getDataUriFileSize = (dataUri: any, isAtob?: any) => {
  const byteCharacters = isAtob
    ? dataUri?.split(',')[1]
    : atob(dataUri?.split(',')[1])
  const byteNumbers = new Array(byteCharacters?.length)

  for (let i = 0; i < byteCharacters?.length; i++) {
    byteNumbers[i] = byteCharacters?.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray])

  return blob?.size
}

export function isDataUri(variable: any) {
  const pattern =
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9]+)?(;[a-zA-Z0-9-]+=[a-zA-Z0-9-]+)*;base64,(.*)$/
  return pattern.test(variable)
}

export const checkConsecutiveTimeSlots = (
  timeSlots: any[],
  duration: number
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const dateObjects: any = timeSlots
      .map((time) => new Date(`2000-01-01T${time}:00`))
      .sort((a: any, b: any) => a - b)
    for (let i = 0; i < dateObjects.length - 1; i++) {
      const diffInMs = dateObjects[i + 1] - dateObjects[i]
      if (diffInMs !== duration * 60 * 1000) {
        reject(false) // time slots are not consecutive
        return // exit the function early if time slots are not consecutive
      }
    }
    resolve(true) // time slots are consecutive
  })
}

export const sortArray = (arr: any[]) => {
  const sorted =
    arr &&
    arr.length > 0 &&
    arr.sort((a: any, b: any) => {
      const timeA = a.value
      const timeB = b.value
      if (timeA < timeB) {
        return -1
      }
      if (timeA > timeB) {
        return 1
      }
      return 0
    })
  return sorted
}

export const appointmentDuration = (slots: any, duration: number) => {
  return slots?.length * duration
}

export const compareDates = (d1: any, d2: any) => {
  let date1 = new Date(d1).getTime()
  let date2 = new Date(d2).getTime()

  if (date1 < date2) {
    console.log(`${d1} is less than ${d2}`)
    return false
  } else if (date1 > date2) {
    console.log(`${d1} is greater than ${d2}`)
    return false
  } else {
    console.log(`Both dates are equal`)
    return true
  }
}

export const getCustomDateFromTime = (time: string) => {
  const h: any = time.split(':')[0] || time
  const m: any = time.split(':')[1] || 0

  return 60 * parseInt(h) + parseInt(m)
}

export const checkDoctorAvailabilty = ({ day, timeSlot, shift }: any) => {
  const {
    shift_one_start,
    shift_one_end,
    shift_one_days,
    shift_two_start,
    shift_two_end,
    shift_two_days,
  } = shift
  if (shift) {
    if (shift_one_days?.includes(day)) {
      const startDate = getCustomDateFromTime(shift_one_start)
      const midDate = getCustomDateFromTime(timeSlot)
      const lastDate = getCustomDateFromTime(shift_one_end)

      if (startDate < midDate && lastDate > midDate) {
        return true
      }
    }

    if (shift_two_days?.includes(day)) {
      const startDate = getCustomDateFromTime(shift_two_start)
      const midDate = getCustomDateFromTime(timeSlot)
      const lastDate = getCustomDateFromTime(shift_two_end)
      if (startDate < midDate && lastDate > midDate) {
        return true
      }
    }
  } else {
    return false
  }
  return false
}

export const createSlots = (
  startTime: any,
  endTime: any,
  genrateSlots?: Boolean
) => {
  const slots: any = []

  while (startTime < endTime) {
    const slotEndTime = new Date(startTime.getTime() + 15 * 60000) // add 15 minutes to the start time
    const startTimeHours = startTime.getHours()
    const startTimeMinutes = startTime.getMinutes()
    slots.push(
      `${startTimeHours < 10 ? `0${startTimeHours}` : startTimeHours}:${
        startTimeMinutes < 10 ? `0${startTimeMinutes}` : startTimeMinutes
      }`
    )
    startTime = slotEndTime
  }
  if (slots?.length > 0 && genrateSlots) {
    const tempArray = slots?.map((item: any, index: number) => {
      const startTime = item
      const endTime = moment(startTime, 'HH:mm')
        .add(15, 'minutes')
        .format('HH:mm')
      const label = `${startTime} to ${endTime}`
      return { label, value: item }
    })
    return tempArray
  } else {
    return slots
  }
}

export const getShiftTimeRange = (data: any[]) => {
  let smallestTime: any = null
  let highestTime: any = null

  data.length > 0 &&
    data.forEach((shift: any) => {
      // parse shift times into Date objects
      const start1 = new Date(
        `2023-01-01T${shift?.shift_one_start
          ?.replace(/^(\d{1}):/, '0$1:')
          ?.replace(/:(\d{1})$/, ':0$1')}`
      )
      const end1 = new Date(
        `2023-01-01T${shift.shift_one_end
          ?.replace(/^(\d{1}):/, '0$1:')
          ?.replace(/:(\d{1})$/, ':0$1')}`
      )
      const start2 = new Date(
        `2023-01-01T${shift.shift_two_start
          ?.replace(/^(\d{1}):/, '0$1:')
          ?.replace(/:(\d{1})$/, ':0$1')}`
      )
      const end2 = new Date(
        `2023-01-01T${shift.shift_two_end
          ?.replace(/^(\d{1}):/, '0$1:')
          ?.replace(/:(\d{1})$/, ':0$1')}`
      )

      // update smallest and highest times seen so far
      if (!smallestTime || start1 < smallestTime) {
        smallestTime = start1
      }
      if (!smallestTime || start2 < smallestTime) {
        smallestTime = start2
      }
      if (!highestTime || end1 > highestTime) {
        highestTime = end1
      }
      if (!highestTime || end2 > highestTime) {
        highestTime = end2
      }
    })
  return {
    hourStart: smallestTime?.getHours() || 0,
    hourEnd: highestTime?.getHours() || 23,
  }
}

export const getValuesForSelectOptions = (arr1: any[], arr2: any[]) => {
  const matchingItems = arr2.filter((obj) => arr1?.includes(obj._id))
  let options: any[] = []
  options =
    matchingItems.length > 0
      ? matchingItems?.map((item) => {
          return { label: item?.name, value: item?._id }
        })
      : []
  return options
}

export const navigateAfterLogin = (userRole: string) => {
  const permissionsObject: any = {
    MC_ADMIN: {
      // navigateTo: 'medicalcenter/branch',
      navigateTo: 'lead/leads',
    },
    RECEPTIONIST: {
      navigateTo: '/receptionist',
    },
    DOCTOR: {
      navigateTo: '/doctor',
    },
    LAB_SUPERVISOR: {
      navigateTo: '/job',
    },
    RADIOLOGY_SUPERVISOR: {
      navigateTo: '/job',
    },
    PHARMACY_SALESPERSON: {
      navigateTo: '/pharmacy',
    },
    DENTIST: {
      navigateTo: '/dentist',
    },
  }
  let permission = permissionsObject[userRole] || {}
  return permission
}
/* Object to change default styles of searchable select - React Select */

export const handleAddSearchableSelectStyle = (tempDimensionObj: any) => {
  return {
    control: (base: any, state: any) => ({
      ...base,
      border: '1px solid #CDD4D8',
      width: tempDimensionObj.width,
      height: tempDimensionObj.height,
      minHeight: '0px',
      background: '#FFFFFF',
      borderRadius: '6px',
      cursor: 'pointer',
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: '1px solid #CDD4D8',
      },
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
  }
}
export const searchableSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    border: '1px solid #CDD4D8',
    width: '234px',
    // height: '42px',
    background: '#FFFFFF',
    borderRadius: '6px',
    cursor: 'pointer',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: '1px solid #CDD4D8',
    },
  }),
  indicatorSeparator: (styles: any) => ({ display: 'none' }),
  menuList: (base: any) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#a49e9e',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#a49e9e',
    },
  }),
}

/* Object to change default styles of searchable select - React Select */

/* Object to change default styles of searchable select for data table - React Select */
export const searchableSelectStyleForDataTable = {
  control: (base: any, state: any) => ({
    ...base,
    border: '1px solid #CDD4D8',
    width: '150px',
    minHeight: '0px',
    height: '29px',
    background: '#FFFFFF',
    borderRadius: '6px',
    cursor: 'pointer',
    padding: '0px',
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: '1px solid #CDD4D8',
    },
  }),
  indicatorSeparator: (styles: any) => ({ display: 'none' }),
}
/* Object to change default styles of searchable select for data table - React Select */

export const formatDate = (dateString: any) => {
  var date = new Date(dateString)
  var year = date.getFullYear()
  var month = String(date.getMonth() + 1).padStart(2, '0')
  var day = String(date.getDate()).padStart(2, '0')

  var outputString = year + '-' + month + '-' + day

  return outputString
}
/* Object to change default styles of searchable select for data table - React Select */

/* Function to generate random unique Id */
export function uniqueID() {
  return Math.floor(Math.random() * Date.now())
}
/* Function to generate random unique Id */

export function allowedNumberOfDigitsAfterDecimal(
  value: any,
  totalDigits: any
) {
  let tempVal = value ? value : 0
  let tempDigitsAfterDecimal = totalDigits
  if (!isNaN(tempVal)) {
    tempVal = tempVal * 1
  }
  tempVal = tempVal?.toFixed(tempDigitsAfterDecimal)

  return tempVal
}

export const checkSameImgCategory = (records: any[], matchKey: string) => {
  if (records.length === 0) {
    return false // No records to compare
  }

  const firstImgCategory = records[0].diag[matchKey]
  if (records?.length > 0) {
    for (let i = 1; i < records.length; i++) {
      if (records[i].diag[matchKey] !== firstImgCategory) {
        return false // Found a different img_category
      }
    }
  }

  return true // All img_category values are the same
}

export const createServiceArray = (
  service: any,
  arr: any,
  plan: any,
  user: any,
  isIpd?: boolean,
  isSeparateService?: boolean
) => {
  try {
    const {
      name,
      price,
      discount,
      sessions,
      _id,
      insurance_plan_id,
      insurance_plan_name,
      department,
      service_discounted_price,
    } = service
    const { doctor_id, doctor_name, attended_by_id } = user
    const netPrice = allowedNumberOfDigitsAfterDecimal(
      (service.price || 0) - service.discount,
      3
    )

    const finalDiscount =
      insurance_plan_id && service_discounted_price
        ? department?.discount
          ? (department?.discount * service_discounted_price) / 100
          : plan?.discount
          ? (plan?.discount * service_discounted_price) / 100
          : discount
        : discount
    // Create multiple rows for each service based on the number of sessions
    for (let i = 1; i <= sessions; i++) {
      // Create a new row of data for each session of the service
      let rowData = {
        treatmentPlanName: i === 1 ? plan?.label : '-',
        plan_id: plan?.value,
        name: i === 1 ? name : '-',
        insurance_plan_name: insurance_plan_name ? insurance_plan_name : '-',
        price:
          insurance_plan_id && service_discounted_price
            ? service_discounted_price
            : price,
        discount: finalDiscount,
        discountPercantage:
          insurance_plan_id && service_discounted_price
            ? (Number(discount) * 100) / service_discounted_price || 0
            : (Number(discount) * 100) / price || 0,
        sessionsIndex: i,
        sessions: i === 1 ? sessions : '-',
        netPrice,
        service_id: _id,
        sessionId: uniqueID(),
        doctor_id,
        doctor_name,
        status: isIpd ? 'attended' : 'new',
        isStatusDisable: isIpd ? true : false,
        billable: true,
        billed:
          insurance_plan_id || isIpd || isSeparateService
            ? 'to-be-billed'
            : 'not-billed',
        note: '',
        attended_by_id,
        insurance_plan_id,
      }

      // Add the row data to the table data array
      arr.push(rowData)
    }
  } catch (e) {
    console.log('e', e)
  }
}

export const createServiceData = (
  selectedPlan: any,
  user: any,
  isIpd?: boolean
) => {
  // Create an array to store the table data
  const tableData: any[] = []

  // Iterate over the service_ids array of the selected plan
  selectedPlan.service_ids.forEach((service: any) => {
    createServiceArray(service, tableData, selectedPlan, user, isIpd)
  })

  // Now you can use the tableData array to populate the table component
  return tableData
}

export const checkExpiryDate = (date: any) => {
  let date1 = new Date(date)
  let currentDate = new Date()

  if (date1 < currentDate) {
    return false
  } else if (date1 > currentDate) {
    return true
  } else {
    return false
  }
}

export const handleCalculatePriceAndDiscount = (arr: any[]) => {
  let discount = 0,
    totalPrice = 0,
    netPrice = 0
  arr.forEach((element: any) => {
    discount += Number(element.discount) || 0
    totalPrice += Number(element.price) || 0
    // netPrice += Number(element.netPrice) || 0
  })
  netPrice = totalPrice - discount
  return { discount, totalPrice, netPrice }
}

export const getUniqueServiceIds = (data: any) => {
  const uniqueIds = new Set()

  for (const obj of data) {
    obj.sessions !== '-' &&
      uniqueIds.add({ id: obj.service_id, sessions: Number(obj.sessions) })
  }

  return Array.from(uniqueIds)
}

// capitalize first letter
export const capitalizeFirstLetter = (str: any) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const handleCalculatePriceDiscountAndNetAmount = (arr: any[]) => {
  let discount = 0,
    totalPrice = 0,
    netAmount = 0
  arr?.forEach((element: any) => {
    discount += Number(element.discount) || 0
    totalPrice += Number(element.unitPrice) || 0
    // netAmount += Number(element.netAmount) || 0;
  })
  netAmount = totalPrice - discount

  return { discount, totalPrice, netAmount }
}

export const handleKeyDown = (e: any) => {
  const { value } = e.target
  const [beforeDecimal, afterDecimal] = value.split('.')

  if (beforeDecimal.length === 1) {
    if (e.target.value.length >= 5 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (beforeDecimal.length === 2) {
    if (e.target.value.length >= 6 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (beforeDecimal.length === 3) {
    if (e.target.value.length >= 7 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (beforeDecimal.length === 4) {
    if (e.target.value.length >= 8 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (beforeDecimal.length === 5) {
    if (e.target.value.length >= 9 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (beforeDecimal.length === 6) {
    if (e.target.value.length >= 10 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else if (afterDecimal === undefined) {
    if (e.target.value.length >= 5 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  } else {
    return
  }
}

export const handleCalculateTotalAmountForPaymentMode = (arr: any[]) => {
  let amount: any = 0
  arr?.forEach((element: any) => {
    amount += Number(element.amount) || 0
  })
  const total = +amount?.toFixed(3)
  return { amount: total }
}

export const doPayment = (patientInvoiceData: any) => {
  if (
    patientInvoiceData?.status === 'DRAFT' ||
    patientInvoiceData?.patient_type === 'OUT PATIENT'
  ) {
    return true
  } else {
    return false
  }
}

export const isServiceEditable = (patientInvoiceData: any) => {
  if (
    patientInvoiceData?.patient_insurance_id &&
    patientInvoiceData?.insurance_approval_no
  ) {
    return false
  } else if (
    patientInvoiceData?.status === 'DRAFT' ||
    patientInvoiceData?.patient_type === 'OUT PATIENT'
  ) {
    return true
  } else {
    return false
  }
}

export const handlePadStart = (invoiceNo: any) => {
  let padstartInvoiceNumber = invoiceNo
  let invoice_number = String(padstartInvoiceNumber).padStart(6, '0')
  return invoice_number
}

export const handlePaymentCondtions = (labInformationData: any) => {
  if (
    labInformationData?.status === 'DRAFT' ||
    labInformationData?.patient_type === 'OUT PATIENT'
  ) {
    return true
  } else {
    return false
  }
}

// fucntion for copy text
export const hanleCopy = async (text: string, toolTipText: string) => {
  try {
    await navigator.clipboard.writeText(text)
    store.dispatch(setToolTipMessage(toolTipText))
  } catch (err) {
    console.log('error', err)
  }
}

export const handleCalculateTotalAmountServices = (arr: any[]) => {
  let amount: any = 0
  let total_sessions = 0
  if (arr && arr?.length > 0) {
    arr?.forEach((element: any) => {
      amount += Number(element?.session_amount) || 0
      total_sessions += Number(element?.sessions) || 0
    })
  }

  return { amount: amount, total_sessions }
}

export const extractActivitiesPayload = (data: any[]) => {
  const activities: any = []

  data.forEach((role) => {
    role.actionTabs.forEach((tab: any) => {
      tab.activity.forEach((activity: any) => {
        activities.push({
          module_id: activity?.moduleId,
          has_permission: activity?.hasPermission,
          is_default: true,
          activity_id: activity?.activityId,
        })
      })
    })
  })

  return activities
}

export const filterSideBarData = (idsArr: any[], mainArr: any[]) => {
  const filteredData = mainArr.filter((item: any) => idsArr.includes(item.id))
  return filteredData
}

export const filterRouteData = (idsArr: any, mainArr: any) => {
  let tempArr: any = []

  tempArr = mainArr.map((item: any) => {
    if (item?.children && item?.children?.length > 0) {
      let childArr: any = []
      childArr = item?.children?.filter(
        (route: any) => idsArr.includes(route.id) || route?.id === 'public'
      )
      return {
        ...item,
        children: childArr,
      }
    } else {
      return item
    }
  })
  return tempArr
}

// restrict some input symbol to enter
export const restrictInputChar = (e: any) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const blockInvalidCharacter = (e: any) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const getSearchQueryinObject = (queryString: any) => {
  const params: any = new URLSearchParams(queryString)
  const queryParams: any = {}

  for (const [key, value] of params) {
    queryParams[key] = value
  }

  return queryParams
}

export const formatOutput = (input: any) => {
  const formattedOutput = []

  for (const key in input) {
    const value = input[key]
    const formattedValue =
      typeof value === 'boolean' ? (value ? 'yes' : 'no') : `'${value}'`
    formattedOutput.push(`${key}: ${formattedValue}`)
  }

  return formattedOutput.join(', ')
}

export const removeProperties = (data: any[], propertiesToRemove: any[]) => {
  return data.map((item) => {
    const newItem = { ...item }
    propertiesToRemove.forEach((property) => {
      delete newItem[property]
    })
    return newItem
  })
}

export const requestToDownloadPdf = (
  urls: string,
  data: any,
  name: string,
  setLoading: any,
  type?: string
) => {
  setLoading(true)
  axios({
    method: 'post',
    url: urls,
    data: data,
    responseType: 'blob',
  })
    .then((response: any) => {
      setLoading(false)
      if (response.type === 'application/pdf') {
        var link: any = document.createElement('a')
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        )
        link.href = url
        const newWindow: any = window.open(url, '_blank')
        if (type === 'print') {
          newWindow.print()
        }
        // if (type === 'print') {
        //   const url = window.URL.createObjectURL(
        //     new Blob([response], { type: 'application/pdf' })
        //   )
        //   link.href = url
        //   const newWindow: any = window.open(url, '_blank')
        //   newWindow.print()
        // }  else {
        //   link.href = window.URL.createObjectURL(
        //     new Blob([response], { type: 'application/octet-stream' })
        //   )
        //   link.download = `${name}.pdf` //pdf name

        //   link.click()
        // }
        document.body.appendChild(link)
        setTimeout(function () {
          window.URL.revokeObjectURL(link)
        }, 200)
      } else {
        let toastData = {
          message: 'No data exist',
          type: warning,
        }
        store.dispatch(setMessage(toastData))
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
    })
}

export const calculateTotalMinutes = (start_time: any, end_time: any) => {
  const [startHour, startMinute] = start_time.split(':').map(Number)
  const [endHour, endMinute] = end_time.split(':').map(Number)

  const startTimeInMinutes = startHour * 60 + startMinute
  const endTimeInMinutes = endHour * 60 + endMinute

  let totalMinutes = endTimeInMinutes - startTimeInMinutes
  if (totalMinutes < 0) {
    totalMinutes += 24 * 60 // Add a day's worth of minutes
  }

  return totalMinutes
}

export const checkModuleExists = (id: any, modules: any[]) => {
  let exist: boolean = false
  exist = modules?.some((module: any) => module?.moduleId === id)
  return exist
}

export const numberToWordConverter = (amount: any) => {
  var words = []
  words[0] = ''
  words[1] = 'One'
  words[2] = 'Two'
  words[3] = 'Three'
  words[4] = 'Four'
  words[5] = 'Five'
  words[6] = 'Six'
  words[7] = 'Seven'
  words[8] = 'Eight'
  words[9] = 'Nine'
  words[10] = 'Ten'
  words[11] = 'Eleven'
  words[12] = 'Twelve'
  words[13] = 'Thirteen'
  words[14] = 'Fourteen'
  words[15] = 'Fifteen'
  words[16] = 'Sixteen'
  words[17] = 'Seventeen'
  words[18] = 'Eighteen'
  words[19] = 'Nineteen'
  words[20] = 'Twenty'
  words[30] = 'Thirty'
  words[40] = 'Forty'
  words[50] = 'Fifty'
  words[60] = 'Sixty'
  words[70] = 'Seventy'
  words[80] = 'Eighty'
  words[90] = 'Ninety'
  amount = amount.toString()
  let atemp = amount.split('.')
  let number = atemp[0].split(',').join('')
  let n_length = number.length
  let words_string = ''
  if (n_length <= 9) {
    let n_array: any = [0, 0, 0, 0, 0, 0, 0, 0, 0]
    let received_n_array = []
    for (let i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1)
    }
    for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j]
    }
    for (let i = 0, j = 1; i < 9; i++, j++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        if (n_array[i] === 1) {
          n_array[j] = 10 + parseInt(n_array[j])
          n_array[i] = 0
        }
      }
    }
    let value: any = ''
    for (let i = 0; i < 9; i++) {
      if (i === 0 || i === 2 || i === 4 || i === 7) {
        value = n_array[i] * 10
      } else {
        value = n_array[i]
      }
      if (value !== 0) {
        words_string += words[value] + ' '
      }
      if (
        (i === 1 && value !== 0) ||
        (i === 0 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += 'Crores '
      }
      if (
        (i === 3 && value !== 0) ||
        (i === 2 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += 'Lakhs '
      }
      if (
        (i === 5 && value !== 0) ||
        (i === 4 && value !== 0 && n_array[i + 1] === 0)
      ) {
        words_string += 'Thousand '
      }
      if (
        i === 6 &&
        value !== 0 &&
        n_array[i + 1] !== 0 &&
        n_array[i + 2] !== 0
      ) {
        words_string += 'Hundred and'
      } else if (i === 6 && value !== 0) {
        words_string += 'Hundred '
      }
    }
    words_string = words_string.split('  ').join(' ')
  }
  return words_string
}

// 2 number To Word Converter Function

var ones = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
]
var tens = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]
var teens = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
]

const convert_millions: any = (num: any) => {
  if (num >= 1000000) {
    return (
      convert_millions(Math.floor(num / 1000000)) +
      ' million ' +
      convert_thousands(num % 1000000)
    )
  } else {
    return convert_thousands(num)
  }
}

function convert_thousands(num: any) {
  if (num >= 1000) {
    return (
      convert_hundreds(Math.floor(num / 1000)) +
      ' thousand ' +
      convert_hundreds(num % 1000)
    )
  } else {
    return convert_hundreds(num)
  }
}

function convert_hundreds(num: any) {
  if (num > 99) {
    return ones[Math.floor(num / 100)] + ' hundred ' + convert_tens(num % 100)
  } else {
    return convert_tens(num)
  }
}

function convert_tens(num: any) {
  if (num < 10) return ones[num]
  else if (num >= 10 && num < 20) return teens[num - 10]
  else {
    return (
      tens[Math.floor(num / 10)] +
      '' +
      (ones[num % 10] ? '-' + ones[num % 10] : ones[num % 10])
    )
  }
}

function convert(num: any) {
  if (num === 0) return 'zero'
  else return convert_millions(num)
}

export const NumberToWord = async (number: number) => {
  if (!isNaN(number)) {
    const string = await convert(number).toUpperCase()
    return string
  } else {
    // throw new BadRequest("Please provide a number!!")
  }
}

// export default NumberToWord

export const hasHeaderMenuAccess = (sidebarData: any[], moduleId: string) =>
  sidebarData?.some((item: any) => item?.moduleId === moduleId)

export const isIqualSameDate = ({ aptData, formData }: any) => {
  let isEqual = false
  const time = {
    dateStartHours:
      aptData?.dateStart.getHours() < 10
        ? `0${aptData?.dateStart.getHours()}`
        : aptData?.dateStart.getHours(),
    dateStartMin:
      aptData?.dateStart.getMinutes() < 10
        ? `0${aptData?.dateStart.getMinutes()}`
        : aptData?.dateStart.getMinutes(),
    dateEndHours:
      aptData?.dateEnd.getHours() < 10
        ? `0${aptData?.dateEnd.getHours()}`
        : aptData?.dateEnd.getHours(),
    dateEndMin:
      aptData?.dateEnd.getMinutes() < 10
        ? `0${aptData?.dateEnd.getMinutes()}`
        : aptData?.dateEnd.getMinutes(),
  }
  const timeStart = `${time.dateStartHours}:${time?.dateStartMin}`
  const timeEnd = `${time.dateEndHours}:${time.dateEndMin}`

  if (
    formData?.start_time === timeStart &&
    formData?.end_time === timeEnd &&
    aptData?.appointment_date === formData?.appointment_date
  ) {
    isEqual = true
  } else {
    isEqual = false
  }

  return isEqual
}

export const getBookedSlotsFromAppointmentDuration = (
  appointmentDuration: number,
  appointmentTime: string
) => {
  let addTime = moment(appointmentTime, 'HH:mm')
  addTime.add(appointmentDuration, 'minutes')
  const start_time = addTime.format('HH:mm')
  return start_time
}

export const pageIndexArray = (totalPage: number) => {
  let pageIndexOptions = []
  for (let i = 1; i <= totalPage; i++) {
    pageIndexOptions.push(i)
  }
  return pageIndexOptions
}

export function breakTextIntoLines(doc: any, text: string, maxWidth: number) {
  const unitWidth = doc?.getStringUnitWidth('mm')
  const chunks: string[] = []
  let currentChunk = ''

  for (const word of text.split(' ')) {
    const wordWidth = word?.length * unitWidth
    if (currentChunk?.length * unitWidth + wordWidth > maxWidth) {
      chunks.push(currentChunk)
      currentChunk = ''
    }
    currentChunk += word + ' '
  }

  chunks.push(currentChunk)
  return chunks
}

export const validatePhoneWithCode = (number: string, code: any) => {
  // Example -
  // number - +1 (123) 456-7890
  // format - +. (...) ...-....
  // eslint-disable-next-line eqeqeq
  return String(number).length > String(code).length && number != code
}

export const setShiftTiming = (
  shiftTiming: any,
  hour: any,
  min: any,
  setValue: any
) => {
  if (shiftTiming && shiftTiming?.length > 0) {
    setValue(hour, { label: shiftTiming[0], value: shiftTiming[0] })
    setValue(min, { label: shiftTiming[1], value: shiftTiming[1] })
  }
}

export const isSameCurentAptDate = (aptDate: any) => {
  const currentDate = new Date()
  const rowDate = new Date(aptDate)
  if (currentDate.toDateString() === rowDate.toDateString()) {
    return true
  } else {
    return false
  }
}

export const attachmentName = (name: string) => {
  let nameWithId = name.split('/')[name.split('/').length - 1] ?? ''
  let nameWithoutID = nameWithId
    ? nameWithId.split('-')[nameWithId.split('-').length - 1]
    : ''
  return nameWithoutID
}

export const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '8px 10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
    color: '#797979',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#797979',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: '#797979',
    display: 'none',
  }),
}

export const branchDropdownStyles = {
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '10px',
    // height: "46px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

export const calculateDaysBetweenTwoDates = (selectedDate: any) => {
  let today = new Date()
  let dob = new Date(selectedDate)
  let diff = today.getTime() - dob.getTime()
  let years = Math.floor(diff / 31556736000)
  let daysDiff = Math.floor((diff % 31556736000) / 86400000)
  let months = Math.floor(daysDiff / 30.4167)
  let days = Math.floor(daysDiff % 30.4167)
  let workStr = `${years} y   ${months} m   ${days} d`
  return workStr
}

export const getLastNYears = (n: number) => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = currentYear - n; i <= currentYear; i++) {
    years.push({ label: i, value: i })
  }
  return years
}

export const removeLastTwoCharacters = (str: any) => {
  let string = str.toString()
  if (string.length < 2) {
    return '' // Return an empty string if the input is empty or has only one character
  } else {
    return string.slice(0, -2) // Use the slice() method to remove the last two characters
  }
}

export const removeLastTwoCharactersfour = (str: any) => {
  let string = str.toString()
  if (string.length < 6) {
    return string.slice(0, -2) // Return an empty string if the input is empty or has only one character
  } else {
    return string.slice(0, -4) // Use the slice() method to remove the last two characters
  }
}

export const reactDropdownStyles = {
  control: (provided: any) => ({
    ...provided,
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '8px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#797979',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
}

export const reactVoucherStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '42px',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '8px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#797979',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
}

export const customDropdownStyles = {
  control: (provided: any) => ({
    ...provided,
    boxShadow: 'none',
    height: '42px',
    cursor: 'pointer',
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px !important',
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '8px',
    cursor: 'pointer',
    margin: '0px !important',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#797979',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
}

export const transformDataWithoutTotal = (data: any[]) => {
  const transformedData: any[] = []

  const processAccount = (account: any) => {
    const transformedAccount = {
      acc_no: account.id,
      acc_name: account.name,
      debit: 0,
      credit: 0,
      opening_debit: 0,
      opening_credit: 0,
      closing_debit: 0,
      closing_credit: 0,
      isBold: true,
    }

    transformedData.push(transformedAccount)

    if (account.level_2) {
      for (const level2Item of account.level_2) {
        const level2Transformed = {
          acc_no: level2Item.account_code,
          acc_name: level2Item.name,
          debit: level2Item.debit || 0,
          credit: level2Item.credit || 0,
          opening_debit: level2Item.opening_debit || 0,
          opening_credit: level2Item.opening_credit || 0,
          closing_debit: level2Item.closing_debit || 0,
          closing_credit: level2Item.closing_credit || 0,
          isBold: true,
        }

        transformedData.push(level2Transformed)

        if (level2Item.voucher) {
          for (const voucher of level2Item.voucher) {
            const voucherAccount = {
              acc_no: voucher.account_code,
              acc_name: voucher.name,
              debit: voucher.debit || 0,
              credit: voucher.credit || 0,
              opening_debit: voucher.opening_debit || 0,
              opening_credit: voucher.opening_credit || 0,
              closing_debit: voucher.closing_debit || 0,
              closing_credit: voucher.closing_credit || 0,
              isBold: false,
            }

            transformedData.push(voucherAccount)
          }
        }
      }
    }
  }

  for (const account of data) {
    processAccount(account)
  }

  return transformedData
}

export const transformDataWithTotal = (data: any[]) => {
  const transformedData: any[] = []

  const processAccount = (account: any) => {
    const transformedAccount = {
      acc_no: account.id,
      acc_name: account.name,
      debit: 500,
      credit: 900,
      opening_debit: 200,
      opening_credit: 100,
      closing_debit: 400,
      closing_credit: 100,
      isBold: true,
    }

    transformedData.push(transformedAccount)
    let levelTotals = {
      acc_no: '',
      acc_name: 'Total',
      debit: 0,
      credit: 0,
      opening_debit: 0,
      opening_credit: 0,
      closing_debit: 0,
      closing_credit: 0,
      isBold: true,
    }
    levelTotals.debit += transformedAccount.debit
    levelTotals.credit += transformedAccount.credit
    levelTotals.opening_debit += transformedAccount.opening_debit
    levelTotals.opening_credit += transformedAccount.opening_credit
    levelTotals.closing_debit += transformedAccount.closing_debit
    levelTotals.closing_credit += transformedAccount.closing_credit
    if (account.level_2) {
      for (const level2Item of account.level_2) {
        const level2Transformed = {
          acc_no: level2Item.account_code,
          acc_name: level2Item.name,
          debit: level2Item.debit || 100,
          credit: level2Item.credit || 200,
          opening_debit: level2Item.opening_debit || 300,
          opening_credit: level2Item.opening_credit || 400,
          closing_debit: level2Item.closing_debit || 700,
          closing_credit: level2Item.closing_credit || 900,
          isBold: true,
        }

        transformedData.push(level2Transformed)

        levelTotals.debit += level2Transformed.debit
        levelTotals.credit += level2Transformed.credit
        levelTotals.opening_debit += level2Transformed.opening_debit
        levelTotals.opening_credit += level2Transformed.opening_credit
        levelTotals.closing_debit += level2Transformed.closing_debit
        levelTotals.closing_credit += level2Transformed.closing_credit

        if (level2Item.voucher) {
          for (const voucher of level2Item.voucher) {
            const voucherAccount = {
              acc_no: voucher.account_code,
              acc_name: voucher.name,
              debit: voucher.debit || 0,
              credit: voucher.credit || 0,
              opening_debit: voucher.opening_debit || 0,
              opening_credit: voucher.opening_credit || 0,
              closing_debit: voucher.closing_debit || 0,
              closing_credit: voucher.closing_credit || 0,
              isBold: false,
            }

            transformedData.push(voucherAccount)

            levelTotals.debit += voucherAccount.debit
            levelTotals.credit += voucherAccount.credit
            levelTotals.opening_debit += voucherAccount.opening_debit
            levelTotals.opening_credit += voucherAccount.opening_credit
            levelTotals.closing_debit += voucherAccount.closing_debit
            levelTotals.closing_credit += voucherAccount.closing_credit
          }
        }

        transformedData.push(levelTotals)

        levelTotals = {
          acc_no: '',
          acc_name: 'Total',
          debit: 0,
          credit: 0,
          opening_debit: 0,
          opening_credit: 0,
          closing_debit: 0,
          closing_credit: 0,
          isBold: true,
        }
      }
    }
  }
  for (const account of data) {
    processAccount(account)
  }

  return transformedData
}

export const isDateBetweenTwoDates = (date1: any, date2: any, date3: any) => {
  const D_1 = date1.split('/') as any
  const D_2 = date2.split('/') as any
  const D_3 = date3.split('/') as any

  var d1 = new Date(D_1[2], parseInt(D_1[1]) - 1, D_1[0])
  var d2 = new Date(D_2[2], parseInt(D_2[1]) - 1, D_2[0])
  var d3 = new Date(D_3[2], parseInt(D_3[1]) - 1, D_3[0])

  if (d3 >= d1 && d3 <= d2) {
    return true
  } else {
    return false
  }
}

export const removeDollarSign = (str: string) => {
  if (str.charAt(0) === '$') {
    return str.slice(1)
  }
  // If the first character is not $, return the original string
  return str
}

// function to add styling for last row data in pdf report data

let drawCell = function (data: any) {
  var doc = data.doc
  var rows = data.table.body
  if (rows.length > 0 && data.row.index === rows.length - 1) {
    doc.setFont(undefined, 'bold')
    doc.setFontSize('10')
    doc.setFillColor(255, 255, 255)
  }
}

// Download patient List report data as pdf

export const patientListReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF('p', 'pt', 'a4')
  const width = doc.internal.pageSize.getWidth()
  let tableData: any = []
  data?.forEach((items: any, index: number) => {
    tableData.push([
      items.file_no,
      items.patient_name,
      items.gender,
      items.contact,
      items.nationality ?? '-',
      moment.utc(items.dob).format('DD-MM-YYYY'),
    ])
  })
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 80, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Patient List', width / 2, 120, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        140,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')

  doc.autoTable({
    startY: 155,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [
      ['File No.', 'Name', 'Gender', 'Contact', 'Nationality', 'Date of Birth'],
    ],
    body: tableData,
  })
  doc.save('patient-list.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download invoice wise data List report as pdf

export const invoiceWiseSalesReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF({
    orientation: 'landscape',
  })
  const width = doc.internal.pageSize.getWidth()
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Invoice-wise Details', width / 2, 40, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        50,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')
  for (let i = 0; i < data?.length - 1; i++) {
    if (i > 0) {
      doc.addPage() // Add a new page for subsequent pages
    }

    let tableData: any = []
    data[i]?.invoiceData?.forEach((items: any, index: number) => {
      tableData.push([
        items.invoice_no,
        items.file_no,
        items.patient_name,
        items.invoice_no
          ? items.doctor_name ?? ''
          : items?.test_name
          ? items?.test_name
          : items?.name
          ? items?.name
          : items?.item_name ?? '',
        !items.invoice_no
          ? items?.price || items?.price === 0
            ? items?.price ?? 0
            : items?.totalAmount || items?.totalAmount === 0
            ? items?.totalAmount ?? 0
            : items?.sell_price * items?.quantity ?? 0
          : '',
        items?.discount ?? '',
        !items.invoice_no
          ? items?.unitPrice || items?.unitPrice === 0
            ? roundToTwo(items?.unitPrice - Number(items?.discount ?? 0))
            : roundToTwo(items?.total_amount - Number(items?.discount ?? 0))
          : '',
        items.paid_amount ?? '',
        items.outstanding_amount ?? '',
        items.invoice_no ? (items.patient_insurance_id ? 'Yes' : 'No') : '',
        items.invoice_no
          ? moment.utc(items.createdAt).format('DD-MM-YYYY')
          : '',
      ])
    })

    data[i]?.branch_name &&
      data[i]?.branch_name !== null &&
      doc
        // eslint-disable-next-line eqeqeq
        .text(`Branch: ${data[i]?.branch_name}`, 20, i == 0 ? 60 : 15)
        .setFontSize(10)
        .setFont(undefined, 'bold')

    doc.autoTable({
      // eslint-disable-next-line eqeqeq
      startY: i == 0 ? 80 : 25,
      styles: {
        halign: 'center',
        textColor: '#000',
        fillColor: '#fefefe',
      },
      tableLineColor: '#000',
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: '#000' },
      bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

      head: [
        [
          'Invoice No.',
          'File No.',
          'Name',
          'Doctor Name',
          'Sales',
          'Discount',
          'Net Sales',
          'Received Amount',
          'Due Amount',
          'Insurance',
          'Date',
        ],
      ],
      body: tableData,
      willDrawCell: drawCell,
    })
  }
  const dynamicHeight = doc?.autoTable?.previous?.finalY + 20
  const lastData = data[data?.length - 1]
  doc.autoTable({
    startY: dynamicHeight,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [
      [
        'Description',
        'Sales',
        'Discount',
        'Net Sales',
        'Received Amount',
        'Due Amount',
      ],
    ],
    body: [
      [
        lastData?.name,
        lastData?.grand_price,
        lastData?.grand_discount,
        lastData?.grand_unitPrice - lastData?.grand_discount,
        lastData?.grand_paid_amount,
        lastData?.grand_outstanding_amount,
      ],
    ],
    willDrawCell: drawCell,
  })
  doc.save('invoice-wise-sales.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download service sales wise summary data List report as pdf

export const serviceWiseSalesReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF({
    orientation: 'landscape',
  })
  const width = doc.internal.pageSize.getWidth()
  let tableData: any = []
  data?.forEach((items: any, index: number) => {
    tableData.push([
      items.invoice_no,
      items.file_no ? moment.utc(items.createdAt).format('DD-MM-YYYY') : '',
      items.file_no,
      items.patient_name,
      items.branch_name,
      items.invoice_no
        ? items.doctor_name ?? ''
        : items?.test_name
        ? items?.test_name
        : items?.name
        ? items?.name
        : items?.item_name ?? '',
      !items.invoice_no
        ? items?.price || items?.price === 0
          ? items?.price ?? 0
          : items?.totalAmount || items?.totalAmount === 0
          ? items?.totalAmount ?? 0
          : items?.sell_price * items?.quantity ?? 0
        : '',
      items?.discount ?? '',
      !items.invoice_no
        ? items?.unitPrice || items?.unitPrice === 0
          ? roundToTwo(items?.unitPrice - Number(items?.discount ?? 0))
          : roundToTwo(items?.total_amount - Number(items?.discount ?? 0))
        : '',
      items.invoice_no ? items.total_amount : '',
      items.outstanding_amount,
      items.paid_amount,
    ])
  })
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Sales Detail Report', width / 2, 40, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        50,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')

  doc.autoTable({
    startY: 70,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [
      [
        'Invoice No.',
        'Date',
        'File No.',
        'Name',
        'Branch',
        'Doctor',
        'Sales',
        'Discount',
        'Net Sales',
        'Price',
        'Receivable',
        'Received',
      ],
    ],
    body: tableData,
    willDrawCell: drawCell,
  })
  doc.save('service-sales-summary.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download collection day wise List report as pdf

export const collectionDayWiseReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF({
    orientation: 'landscape',
  })
  const width = doc.internal.pageSize.getWidth()

  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Collection Details', width / 2, 40, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        50,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')
  for (let i = 0; i < data?.length - 1; i++) {
    if (i > 0) {
      doc.addPage() // Add a new page for subsequent pages
    }
    let tableData: any = []
    data[i]?.invoiceData?.forEach((items: any, index: number) => {
      tableData.push([
        items.type === 'date_total' ? '' : items.type,
        items.type === 'Receipt' ? items.receipt_no : items.invoice_no,
        items.file_no,
        items.patient_name,
        items?.name === 'Grand Total:'
          ? 'Grand Total:'
          : items.type === 'date_total'
          ? items?.name
          : items.doctor_name,
        items?.name === 'Grand Total:' ? items?.grand_cash : items.cash,
        items.others,
        items.type === 'date_total'
          ? ''
          : items.patient_insurance_id
          ? items.patient_insurance_id !== null
            ? 'Yes'
            : 'No'
          : 'No',
        items.type === 'date_total'
          ? ''
          : items.type === 'Receipt'
          ? 'Advance'
          : `Sales Amt: ${items.total_amount ?? 0} Disc. Amt: ${
              items?.discount ?? 0
            }`,
      ])
    })

    data[i]?.invoiceData &&
      doc
        .text(
          `Date : ${moment.utc(data[i]?.date).format('DD-MM-YYYY')}`,
          20,
          // eslint-disable-next-line eqeqeq
          i == 0 ? 60 : 15
        )
        .setFontSize(10)
        .setFont(undefined, 'bold')

    doc.autoTable({
      // eslint-disable-next-line eqeqeq
      startY: i == 0 ? 70 : 25,
      styles: {
        halign: 'center',
        textColor: '#000',
        fillColor: '#fefefe',
      },
      tableLineColor: '#000',
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: '#000' },
      bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

      head: [
        [
          'Doc. Type',
          'Doc. No.',
          'File No.',
          'Name',
          'Doctor Name',
          'Cash',
          'KNet / CC',
          'Insurance',
          'Remarks',
        ],
      ],
      body: tableData,
      willDrawCell: drawCell,
    })
  }
  const dynamicHeight = doc?.autoTable?.previous?.finalY + 20
  const lastData = data[data?.length - 1]
  doc.autoTable({
    startY: dynamicHeight,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [['Description', 'Cash', 'KNet / CC']],
    body: [[lastData?.name, lastData?.grand_cash, lastData?.grand_others]],
    willDrawCell: drawCell,
  })
  doc.save('collection-details-.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download doctor wise collection summary data List report as pdf

export const doctorWiseCollectionReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF('p', 'pt', 'a4')
  const width = doc.internal.pageSize.getWidth()
  let tableData: any = []
  data?.forEach((items: any, index: number) => {
    tableData.push([items.doctor_name, items.amount])
  })
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 80, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Doctor Wise Collecton', width / 2, 120, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        140,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')

  doc.autoTable({
    startY: 160,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [['Doctor / Technician', 'Amount']],
    body: tableData,
    willDrawCell: drawCell,
  })
  doc.save('doctor-wise-collection.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download service wise sales summary List report as pdf

export const serviceWiseSaleSummaryReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF({
    orientation: 'landscape',
  })
  const width = doc.internal.pageSize.getWidth()

  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Service Summary', width / 2, 40, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        50,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')
  for (let i = 0; i < data?.length - 1; i++) {
    if (i > 0) {
      doc.addPage() // Add a new page for subsequent pages
    }
    let tableData: any = []
    data[i]?.services?.forEach((items: any, index: number) => {
      tableData.push([
        items.name,
        items.count,
        items.actual_price,
        items.discount,
        items.net_price,
      ])
    })

    data[i]?.department_name &&
      doc
        // eslint-disable-next-line eqeqeq
        .text(`Service: ${data[i]?.department_name}`, 20, i == 0 ? 60 : 15)
        .setFontSize(10)
        .setFont(undefined, 'bold')
    doc.autoTable({
      // eslint-disable-next-line eqeqeq
      startY: i == 0 ? 70 : 25,
      styles: {
        halign: 'center',
        textColor: '#000',
        fillColor: '#fefefe',
      },
      tableLineColor: '#000',
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: '#000' },
      bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

      head: [['Sub Service', 'Count', 'Actual Price', 'Discount', 'Net Price']],
      body: tableData,
      willDrawCell: drawCell,
    })
  }
  const dynamicHeight = doc?.autoTable?.previous?.finalY + 20
  const lastData = data[data?.length - 1]
  doc.autoTable({
    startY: dynamicHeight,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [['Description', 'Count', 'Actual Price', 'Discount', 'Net Price']],
    body: [
      [
        lastData?.name,
        lastData?.grand_count,
        lastData?.grand_actual_price,
        lastData?.grand_discount,
        lastData?.grand_net_price,
      ],
    ],
    willDrawCell: drawCell,
  })
  doc.save('service-wise-sales.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download patient statement data List report as pdf

export const patientStatementReportPdf = (data?: any) => {
  const doc: any = new jsPDF({ orientation: 'landscape' })
  const width = doc.internal.pageSize.getWidth()
  let tableData: any = []
  data?.forEach((items: any, index: number) => {
    tableData.push([
      items.type === 'Invoice' || items.type === 'Receipt'
        ? moment.utc(items.createdAt).format('DD-MM-YYYY')
        : '',
      items.type === 'Invoice' || items.type === 'Receipt' ? items.type : '',
      items.type === 'Invoice'
        ? items.invoice_no
        : items.type === 'Receipt'
        ? items?.receipt_no
        : items?.name ?? items?.test_name ?? items?.item_name,
      items.type === 'Receipt'
        ? items?.advance_amount
        : items.total_amount ?? items?.totalAmount ?? items?.price,
      items.discount,
      items.cash,
      items.others,
    ])
  })
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('Patient Billing Statement', width / 2, 40, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  data[0]?.file_no &&
    doc
      .text(
        `File No. : ${data[0]?.file_no}    Patient Name : ${data[0]?.patient_name}   Mobile No. : ${data[0]?.phone}`,
        20,
        60
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')
  doc.autoTable({
    startY: 70,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [
      [
        'Date',
        'Type',
        'Document No.',
        'Invoice Amt.',
        'Discount',
        'Cash',
        'K-Net / CC',
      ],
    ],
    body: tableData,
    willDrawCell: drawCell,
  })
  doc.save('patient-statement.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

// Download all patient statement data List report as pdf

export const userWiseCollectionReportPdf = (
  data?: any,
  dateFrom?: any,
  dateTo?: any
) => {
  const doc: any = new jsPDF({ orientation: 'landscape' })
  const width = doc.internal.pageSize.getWidth()
  doc
    .text(store.getState()?.login?.branchData?.mc_name, width / 2, 20, {
      align: 'center',
    })
    .setFontSize(16)
    .setFont(undefined, 'bold')
  doc
    .text('User Collection', width / 2, 30, {
      align: 'center',
    })
    .setFontSize(10)
    .setFont(undefined, 'bold')
  dateFrom !== '' &&
    dateTo !== '' &&
    doc
      .text(
        `From: ${moment.utc(dateFrom).format('DD-MM-YYYY')} To: ${moment
          .utc(dateTo)
          .format('DD-MM-YYYY')}`,
        width / 2,
        40,
        {
          align: 'center',
        }
      )
      .setFontSize(10)
      .setFont(undefined, 'bold')
  for (let i = 0; i < data?.length - 1; i++) {
    if (i > 0) {
      doc.addPage() // Add a new page for subsequent pages
    }
    let tableData: any = []
    data[i]?.invoiceData?.forEach((items: any, index: number) => {
      tableData.push([
        items.type === 'Invoice' || items.type === 'Receipt'
          ? moment.utc(items.createdAt).format('DD-MM-YYYY')
          : '',
        items.type === 'Invoice' || items.type === 'Receipt' ? items.type : '',
        items.type === 'Invoice'
          ? items.invoice_no
          : items.type === 'Receipt'
          ? items?.receipt_no
          : items?.name ?? items?.test_name ?? items?.item_name,
        items.cash,
        items.others,
      ])
    })

    data[0]?.file_no &&
      doc
        .text(
          `File No. : ${data[i]?.invoiceData[0]?.file_no}    Patient Name : ${data[i]?.invoiceData[0]?.patient_name}   Mobile No. : ${data[i]?.invoiceData[0]?.phone}`,
          20,
          i === 0 ? 60 : 15
        )
        .setFontSize(10)
        .setFont(undefined, 'bold')
    doc.autoTable({
      startY: i === 0 ? 70 : 25,
      styles: {
        halign: 'center',
        textColor: '#000',
        fillColor: '#fefefe',
      },
      tableLineColor: '#000',
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: '#000' },
      bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

      head: [['Date', 'Type', 'Document', 'Cash', 'KNet / CC']],
      body: tableData,
      willDrawCell: drawCell,
    })
  }
  const dynamicHeight = doc?.autoTable?.previous?.finalY + 20
  const lastData = data[data?.length - 1]
  doc.autoTable({
    startY: dynamicHeight,
    styles: {
      halign: 'center',
      textColor: '#000',
      fillColor: '#fefefe',
    },
    tableLineColor: '#000',
    tableLineWidth: 0.1,

    headStyles: { lineWidth: 0.1, lineColor: '#000' },
    bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

    head: [['Description', 'Cash', 'KNet / CC']],
    body: [[lastData?.name, lastData?.cash, lastData?.others]],
    willDrawCell: drawCell,
  })
  doc.save('user-wise-collection.pdf')
  window.open(doc.output('bloburl'), '_blank')
}

export const convertString = (str: string) => {
  // Convert to lowercase
  const lowerStr = str.toLowerCase()

  // Replace spaces with "hifine" using a regular expression
  const result = lowerStr.replace(/\s+/g, '_')

  return result
}
const roundToTwo = (num: any) => {
  var p = Math.pow(10, 2)
  return Math.round(num * p) / p
}

export const selectedCalView = (path: string) => {
  switch (path) {
    case '/schedular':
      return caladerDropdownData[0]
    case '/schedular/room':
      return caladerDropdownData[1]
    case '/schedular/beds':
      return caladerDropdownData[2]
    default:
      return caladerDropdownData[0]
  }
}

export const isEmptyObj = (obj: any) => {
  return Object.keys(obj).length === 0
}

export const getAptDataFromUnavailable = (
  dataArray: any[],
  isRoom?: boolean
): any[] => {
  const processedData: any[] = []

  dataArray.forEach((data) => {
    const startDate = new Date(data.start_date)
    const endDate = new Date(data.end_date)
    const startTime: string = data.start_time ? data.start_time : '00:00'
    const endTime: string = data.end_time ? data?.end_time : '23:59'
    const startArr = startTime.split(':')
    const endArr = endTime.split(':')

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dateStart = new Date(date)
      const dateEnd = new Date(date)
      dateStart.setHours(parseInt(startArr[0]) ?? 0)
      dateStart.setMinutes(parseInt(startArr[1]) ?? 0)
      dateEnd.setHours(parseInt(endArr[0]) ?? 23)
      dateEnd.setMinutes(parseInt(endArr[1]) ?? 59)
      processedData.push({
        label: 'Unavailable',
        _id: isRoom ? data?.room_id : data?.doctor_id,
        backgroundColor: '#B11313',
        dateStart,
        dateEnd,
        appt_status: 'UNAVAILABLE',
        appt_id: '',
        patient_name: '',
        file_no: '',
        appointment_date: '',
        // patient_id: item?.pnt_user_id,
        patient_id: '',
        patient_phone: '',
        problem_description: '',
        room_id: '',
        doctor: '',
        room: '',
        note: data?.note,
        type: 'unavailability',
      })
    }
  })
  return processedData
}

export const getFirstLastDateOfMonth = (date: any) => {
  // Convert the input to a Date object
  let givenDate = new Date(date)

  // Get the first day of the month
  let firstDay = new Date(givenDate.getFullYear(), givenDate.getMonth(), 1)

  // Get the last day of the month
  let lastDay = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1, 0)

  return { firstDay, lastDay }
}

export const getBookedBedFromUnavailable = (data: any[]) => {
  let newDataArra: any[] = []

  newDataArra = data.map((item) => {
    const bedUnavailability = item?.room_unavailabilities?.map((room: any) => {
      const { start_date, end_date } = room

      const dateStart = new Date(start_date)
      const dateEnd = new Date(end_date)
      dateStart.setHours(0)
      dateStart.setMinutes(0)
      dateEnd.setHours(23)
      dateEnd.setMinutes(59)
      return {
        _id: item?._id,
        label: 'Unavailable',
        bed_name: item?.bed_name,
        bed_no: item?.bed_no,
        backgroundColor: '#B11313',
        dateStart,
        dateEnd,
        patient_name: '',
        file_no: '',
        patient_id: '',
        patient_phone: '',
      }
    })
    return bedUnavailability
  })

  return newDataArra.flat()
}

const convertArrayOfObjectsToCSV = (array: any) => {
  let result: any

  const columnDelimiter = ','
  const lineDelimiter = '\n'
  const keys: any = Object.keys(array[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item: any) => {
    let ctr = 0
    keys.forEach((key: any) => {
      if (ctr > 0) result += columnDelimiter

      result += item[key]
      // eslint-disable-next-line no-plusplus
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

// download sample csv

export const downloadCSV = (array: any, name?: string) => {
  const link = document.createElement('a')
  let csv = convertArrayOfObjectsToCSV(array)
  if (csv == null) return

  const filename = name ? name : 'sample.csv'

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }

  link.setAttribute('href', encodeURI(csv))
  link.setAttribute('download', filename)
  link.click()
}

export const convertArrayToObj = (data: any[]) => {
  return data.reduce((acc, curr) => {
    acc[curr.name] = curr.check
    return acc
  }, {})
}

export const objToArray = (obj: any) => {
  let array: any[] = []
  for (let key in obj) {
    array.push({ name: key, check: obj[key] })
  }
  return array
}

export const filterAppointmentStatusList = (
  masterStatus: {
    title: string
    label: string
    colorCode: string
    bgColor: string
  }[],
  currentStatus: string
) => {
  const excludeArray = matchExclude[currentStatus] || []
  return excludeArray?.length > 0
    ? masterStatus.filter(
        (item: { title: string; label: string; colorCode: string }) =>
          !excludeArray.includes(item.label)
      )
    : []
}
