import { useState, useEffect } from 'react'
import MobileAppoinmentRequestModal from '../../../../pages/mobile-appointment-request/mobile-appointment-request-modal/MobileAppoinmentRequestModal'
import BookingConfirmationModal from '../../../common/modal/booking-confirmation-modal/BookingConfirmationModal'
import StatusConfirmationPopupV3 from '../../../common/modal/status-confirmation-popup/status-confirmation-popupV3/StatusConfirmationPopupV3'
import IpdBookingPopup from '../../../../pages/ipd/ipd-booking/IpdBooking'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../../hooks/index'
import {
  cancelAppointmentStatus,
  getAllMobileAppointment,
} from '../../../../redux/features/mobile-appointment-request/mobileAppointmentRequestAsyncActions'
import {
  getAllBookedBeds,
  getIpdBookDetails,
  updateIpdBooking,
} from '../../../../redux/features/ipd-booking/ipdBookingAsyncActions'
import {
  clearSlotData,
  addColorSchemeData,
} from '../../../../redux/features/appointments/bookingAppointmentsSlice'
import {
  CANCEL_APPOINTMENT,
  UPDATE_IPD_BOOK_TYPE,
  GET_IPD_BOOK_TYPE,
} from '../../../../constants/asyncActionsType'
import { clearIpdBookingDropDownData } from '../../../../redux/features/ipd-booking/ipdBookingSlice'
import Popup from '../../popup/Popup'
import Button from '../../button/Button'
import InfoTooltip from '../../info-tooltip/InfoTooltip'
import ColorScheme from '../../color-scheme/ColorScheme'

import StatusSummaryPopup from '../../modal/view-appointment-popup/status-summary-popup/StatusSummaryPopup'
import {
  caladerDropdownData,
  caladerDropdownDataWithoutBed,
  colorSchemeData,
} from '../../../../constants/data'
import SearchModal from '../../../../pages/ipd/ipd-booking/SearchModal'
import PatientHistoryNotesModal from '../../../../components/common/modal/patient-history-notes-modal/PatientHistoryNotesModal'
import IpdBookPopup from '../../../../pages/ipd/ipd-booking/IpdBookPopup'
import DropdownV2 from '../../dropdown/dropdownv2/DropdownV2'
import { useNavigate, useLocation } from 'react-router'
import OnlinePaymentModal from '../../modal/online-payment-modal/OnlinePaymentModal'
import { CustomModal } from '../../custom-modal/modal'
import PhysioAppointmentBooking from '../../../../pages/physio/page'
import styles from './schedularHeader.module.scss'
import { IPD_BOOKING } from '../../../../config/modules'
import { hasHeaderMenuAccess, selectedCalView } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'

const SchedularHeader = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { getAllMobileAppointPayloadData } = useAppSelector(
    (state) => state.mobileAppointmentRequest
  )
  const { colorSchemeList, sidebarData } = useAppSelector(
    (state) => state.login
  )
  const { branchData } = useAppSelector((state) => state.ipdBooking)
  const { currentViewDate } = useAppSelector((state) => state.appointments)

  // Define state variables
  const [showMobileAppointmentRequest, setShowMobileAppointmentRequest] =
    useState<boolean>(false)
  const [showBookingConfirmationModal, setShowBookingConfirmationModal] =
    useState<boolean>(false)
  const [showStatusConfirmationModal, setStatusConfirmationModal] =
    useState<boolean>(false)
  const [statusSummary, setStatusSummary] = useState<boolean>(false)
  const [togglePayloadData, setTogglePayloadData] = useState<any>({})
  const [showIpdBooking, setIpdBooking] = useState<boolean>(false)
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [patientData, setPateintData] = useState<any>({})
  const [showIpdDescriptionModal, setShowIpdDescriptionModal] =
    useState<boolean>(false)
  const [ipdDescriptionPopupData, setIpdDescriptionPopupData] = useState<any>(
    {}
  )
  const [showIpdNotesModal, setShowIpdNotesModal] = useState<boolean>(false)
  const [ipdNotesPopupData, setIpdNotesPopupData] = useState<any>({})
  const [showIpdBookModal, setShowIpdBookModal] = useState<boolean>(false)
  const [ipdBookModalPopupData, setIpdBookModalPopupData] = useState<any>({})
  const [ipdBookIngModalData, setIpdBookingModalData] = useState<any>({})
  const [selectedItem, setSelectedItem] = useState<any>(caladerDropdownData[0])
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false)
  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({
    patient_default_branch_id: '',
    name: 'Rahul Patel',
    phone: '965989898989800',
    email: 'rahul@yopmail.com',
    invoice_no: '123456',
    amount: '80',
    // link: upayLinkObject?.link,
    // transaction_id: upayLinkObject?.transaction_id,
    note: 'test note',
    transaction_id: '',
    link: 'https://api.upayments.com/live/new-knet-payment?ref=m7OxY4Bqva65014e139319ea424297b81a1694584340164456273065014e145ea45&sess_id=1d2abf928d57c24ca791bc431482c01d&alive=TVZ2MEdtbG8yYA==',
    // link: null,
  })

  const [physioBookingModal, setPhysioBookingModal] = useState(false)

  const handleMobileAppointmentRequestModal = () => {
    setShowMobileAppointmentRequest((prevState) => !prevState)
  }

  const handleBookingConfirmationModal = () => {
    setShowBookingConfirmationModal((prevState) => !prevState)
    dispatch(clearSlotData())
  }

  const handleStatusConfirmationModal = (statusData: any) => {
    setStatusConfirmationModal((prevState) => !prevState)
    setTogglePayloadData(statusData)
  }

  const handleStatusConfirmationModalClose = () => {
    setStatusConfirmationModal((prevState) => !prevState)
    setTogglePayloadData({})
  }

  const handleStatusConfirmationAPICall = (data: any) => {
    const payload = { ...togglePayloadData }
    payload.reason = data.reason
    dispatch(cancelAppointmentStatus(requestGenerator(payload))).then((e) => {
      if (e.type === `${CANCEL_APPOINTMENT}/fulfilled`) {
        dispatch(
          getAllMobileAppointment(
            requestGenerator(getAllMobileAppointPayloadData)
          )
        )
        handleStatusConfirmationModalClose()
      }
    })
  }

  useEffect(() => {
    dispatch(addColorSchemeData(colorSchemeList))
  }, [dispatch, colorSchemeList])

  // handle patient search modal
  const handlePatientSearchModalOpen = () => {
    setShowSearchModal((prevState) => !prevState)
  }

  const handlePatientSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState)
  }

  // funtion for handling IPD booking modal
  const handlIpdBookingModal = () => {
    setPateintData({})
    setIpdBookModalPopupData({})
    setIpdBookingModalData({})
    setIpdBooking((prevState) => !prevState)
    dispatch(clearIpdBookingDropDownData())
  }

  const handlAddIpdBookModal = () => {
    let payalodData = {}
    if (ipdBookIngModalData?.discharge_date) {
      payalodData = {
        branch_id: branchData?._id,
        bed_id: ipdBookModalPopupData?._id,
        ipd_id: ipdBookModalPopupData?.ipd_id,
        patient_id: ipdBookModalPopupData?.patient_id,
        admission_date: ipdBookIngModalData?.admitted_date,
        booking_date: ipdBookIngModalData?.booking_date,
        discharge_date: ipdBookIngModalData?.discharge_date,
        primary_doctor_id: ipdBookIngModalData?.primary_doctor?.value,
      }
    } else {
      payalodData = {
        branch_id: branchData?._id,
        bed_id: ipdBookModalPopupData?._id,
        ipd_id: ipdBookModalPopupData?.ipd_id,
        patient_id: ipdBookModalPopupData?.patient_id,
        admission_date: ipdBookIngModalData?.admitted_date,
        booking_date: ipdBookIngModalData?.booking_date,
        primary_doctor_id: ipdBookIngModalData?.primary_doctor?.value,
      }
    }
    dispatch(updateIpdBooking(requestGenerator(payalodData))).then((e) => {
      if (e.type === `${UPDATE_IPD_BOOK_TYPE}/fulfilled`) {
        setPateintData({})
        setIpdBookModalPopupData({})
        setIpdBookingModalData({})
        setIpdBooking((prevState) => !prevState)
        dispatch(clearIpdBookingDropDownData())
        if (path === '/schedular/beds') {
          dispatch(
            getAllBookedBeds(requestGenerator({ branch_id: branchData?._id }))
          )
        } else {
          navigate('/schedular/beds', {
            state: { notRenderSidebar: true },
          })
        }
      }
    })
  }

  // handle ipd booking notes modal
  const handleIpdBookingDescriptionModalOpen = (descriptionData: any) => {
    const ipdDescriptionData = {
      diag_note: descriptionData?.description_notes,
      diag_apt_date: new Date(),
    }
    setIpdDescriptionPopupData(ipdDescriptionData)
    setShowIpdDescriptionModal((prevState) => !prevState)
  }
  const handleIpdBookingDescriptionModalClose = () => {
    setIpdDescriptionPopupData({})
    setShowIpdDescriptionModal((prevState) => !prevState)
  }

  const handleIpdBookingNotesModalOpen = (descriptionData: any) => {
    const ipdNotesData = {
      diag_note: descriptionData?.notes,
      diag_apt_date: new Date(),
    }
    setIpdNotesPopupData(ipdNotesData)
    setShowIpdNotesModal((prevState) => !prevState)
  }
  const handleIpdBookingNotesModalClose = () => {
    setIpdNotesPopupData({})
    setShowIpdNotesModal((prevState) => !prevState)
  }

  // handle ipd book modal
  const handleIpdBookModalOpen = (bookModalData: any) => {
    const ipdBookModalData = {
      ...bookModalData,
    }
    const payload = {
      bed_id: bookModalData?._id,
      ipd_id: bookModalData?.ipd_id,
    }
    dispatch(getIpdBookDetails(requestGenerator(payload))).then((e) => {
      if (e.type === `${GET_IPD_BOOK_TYPE}/fulfilled`) {
        setIpdBookModalPopupData(ipdBookModalData)
        setShowIpdBookModal((prevState) => !prevState)
      }
    })
  }
  const handleIpdBookModalClose = () => {
    setIpdBookModalPopupData({})
    setIpdBookingModalData({})
    setShowIpdBookModal((prevState) => !prevState)
  }
  const handleIpdBookModal = (data: any) => {
    setIpdBookingModalData(data)
    setShowIpdBookModal((prevState) => !prevState)
  }

  const bedColorScheme = [
    {
      title: 'Booked',
      label: 'BOOKED',
      colorCode: '#5936F1',
    },
  ]
  const path = useLocation().pathname
  const campaignData = useLocation().state
  const invoiceOnlinePaymentModalClose = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal)
    setOnlinePaymentLinkData({})
  }

  useEffect(() => {
    // if (path === '/schedular') {
    const item = selectedCalView(path)
    setSelectedItem(item)
    // }
  }, [path])
  return (
    <>
      {statusSummary && (
        <Popup
          Children={StatusSummaryPopup}
          handleClose={() => setStatusSummary(false)}
          popData={{ date: currentViewDate ? currentViewDate : '' }}
        />
      )}
      {showMobileAppointmentRequest && (
        <Popup
          Children={MobileAppoinmentRequestModal}
          handleClose={handleMobileAppointmentRequestModal}
          handleSubmit={handleBookingConfirmationModal}
          handleOpen={handleStatusConfirmationModal}
        />
      )}
      {showBookingConfirmationModal && (
        <Popup
          Children={BookingConfirmationModal}
          handleClose={handleBookingConfirmationModal}
        />
      )}
      {showStatusConfirmationModal && (
        <Popup
          Children={StatusConfirmationPopupV3}
          handleClose={handleStatusConfirmationModalClose}
          handleOpen={handleStatusConfirmationAPICall}
          message={t('Common.Are you sure') || 'Are you sure ?'}
          heading={
            t('StatusConfirmation.Cancel appointment') || 'Cancel appointment'
          }
        />
      )}
      {showIpdBooking && (
        <Popup
          Children={IpdBookingPopup}
          handleOpen={handlePatientSearchModalOpen}
          handleClose={handlIpdBookingModal}
          handleYes={handleIpdBookingDescriptionModalOpen}
          handleRowClick={handleIpdBookingNotesModalOpen}
          handleSubmit={handleIpdBookModalOpen}
          popData={patientData}
          handleSubmitData={ipdBookIngModalData}
          handleRowData={ipdBookModalPopupData}
          handleConfirm={handlAddIpdBookModal}
        />
      )}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          handleClose={handlePatientSearchModalClose}
          handleRowClick={(item: any) => {
            setPateintData(item)
            handlePatientSearchModalClose()
          }}
        />
      )}
      {showIpdDescriptionModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={handleIpdBookingDescriptionModalClose}
          popData={ipdDescriptionPopupData}
          heading={t('DoctorDiagnosis.Description') || 'Description'}
        />
      )}
      {showIpdNotesModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={handleIpdBookingNotesModalClose}
          popData={ipdNotesPopupData}
          heading={t('Common.Notes') || 'Notes'}
        />
      )}
      {showIpdBookModal && (
        <Popup
          Children={IpdBookPopup}
          popData={ipdBookModalPopupData}
          handleClose={handleIpdBookModalClose}
          handleYes={handleIpdBookModal}
        />
      )}
      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={() => invoiceOnlinePaymentModalClose()}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/invoice/information"
        />
      )}
      <CustomModal
        showModal={physioBookingModal}
        closeModal={() => setPhysioBookingModal(false)}
        title={t('RoomBooking.Room Booking') || 'Room Booking'}
        width="65dvw"
        height="95dvh"
      >
        <PhysioAppointmentBooking
          closeModal={() => setPhysioBookingModal(false)}
        />
      </CustomModal>
      <div className={styles.mainHeaderContainer}>
        <div className={styles.buttonContainer}>
          <Button
            title={t('SchedularHeader.ShareLink') || 'Share Payment Link'}
            customClass={styles.buttonOutline}
            type="button"
            disable={campaignData?.lead?._id ? true : false}
            handleClick={() => setShowOnlinePaymentModeModal(true)}
          />
          {/* <Button
            title="OT Booking"
            customClass={styles.buttonOutline}
            disable={campaignData?.lead?._id ? true : false}
          /> */}
          <Button
            title={t('RoomBooking.Room Booking') || 'Room Booking'}
            customClass={styles.buttonOutline}
            disable={campaignData?.lead?._id ? true : false}
            handleClick={() => setPhysioBookingModal(true)}
          />
          {hasHeaderMenuAccess(sidebarData, IPD_BOOKING) && (
            <Button
              title={t('IPDBooking.IPD Booking') || 'IPD Booking'}
              customClass={styles.buttonOutline}
              handleClick={handlIpdBookingModal}
              disable={campaignData?.lead?._id ? true : false}
            />
          )}

          <Button
            title={
              t('SchedularHeader.MoAptReq') || 'Mobile Appointment Request'
            }
            customClass={styles.buttonOutline}
            disable={campaignData?.lead?._id ? true : false}
            handleClick={handleMobileAppointmentRequestModal}
          />
          <Button
            title={t('ViewAppointment.Status Summary') || 'Status Summary'}
            customClass={styles.buttonOutline}
            disable={campaignData?.lead?._id ? true : false}
            handleClick={() => setStatusSummary(true)}
          />
          <DropdownV2
            data={
              hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
                ? caladerDropdownData
                : caladerDropdownDataWithoutBed
            }
            type={t('SchedularHeader.Calendar View') || 'Calendar View'}
            keyName="name"
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            notShowAllOption={true}
            handleClick={(item: any) => {
              if (
                item?.navigate !== null &&
                hasHeaderMenuAccess(sidebarData, IPD_BOOKING)
              ) {
                setSelectedItem({ name: item?.name, _id: item?._id })
                navigate(item?.navigate, { state: { notRenderSidebar: true } })
              }
            }}
            isDisable={campaignData?.lead?._id ? true : false}
            customClass={styles.dropownContainer}
            customClassTitle={styles.custonClassTitle}
            customClassBox={styles.customClassBOx}
            customClassBody={styles.customClassBody}
          />
        </div>
        <div className={styles.colorSchemeContainer}>
          <InfoTooltip
            title={t('SchedularHeader.Color Scheme') || 'Color Scheme'}
            Children={ColorScheme}
            tooltipData={
              path === '/schedular/beds'
                ? bedColorScheme
                : colorSchemeList && colorSchemeList.length > 0
                ? colorSchemeList
                : colorSchemeData
            }
          />

          {/* {path !== '/schedular/beds' && (
            <InfoTooltip
              title=" Allow double bookings"
              Children={DoubleInfoBookingInfo}
              customClassChild={styles.doubleBookingMsg}
              tooltipData={{
                message: "No. of Appointments per slot",
                count: 3,
              }}
            />
          )} */}
        </div>
      </div>
    </>
  )
}

export default SchedularHeader
