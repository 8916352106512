import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Scheduler } from 'smart-webcomponents-react/scheduler'
import 'smart-webcomponents-react/source/styles/smart.default.css'
import '../schedular.css'
import styles from '../bookingappointment.module.scss'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { setRoomBookingDetailById } from '../../../redux/features/physio/physioSlice'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { addBranchData } from '../../../redux/features/ipd-booking/ipdBookingSlice'
import { warning } from '../../../constants/data'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  clearData,
  setCurrentViewDate,
} from '../../../redux/features/appointments/bookingAppointmentsSlice'
import { cancelAppointmentStatus } from '../../../redux/features/mobile-appointment-request/mobileAppointmentRequestAsyncActions'
import { CANCEL_APPOINTMENT } from '../../../constants/asyncActionsType'
import moment from 'moment'
import Popup from '../../../components/common/popup/Popup'

import StatusConfirmationPopupV3 from '../../../components/common/modal/status-confirmation-popup/status-confirmation-popupV3/StatusConfirmationPopupV3'

import AppointmentDetails from '../../../components/common/modal/appointment-details/AppointmentDetails'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import PhysioAppointmentBooking from '../../physio/page'
import Loader from '../../../components/common/spinner/Loader'
import DropdownV2 from '../../../components/common/dropdown/dropdownv2/DropdownV2'
import ReloadButton from '../../../components/common/reload-button/ReloadButton'
import PaginationV2 from '../../../components/common/pagination/paginationv2/PaginationV2'
import noRecordImage from '../../../assets/images/noRecordsFound.png'
import {
  getAllPhysioAppointments,
  getAllRoomsForCalnedar,
  getDocUnavailability,
} from '../../../redux/features/appointments/bookingAppointmentAsyncActions'
import { getAptDataFromUnavailable } from '../../../utils/utils'

interface IRoomSchedular {}

const RoomSchedular: FC<IRoomSchedular> = () => {
  const scheduler = useRef<any>(null)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    doctorLoading,
    dataSource,
    loading,
    resources,
    totalCount,
    doctorData: roomData,
    hasMorePage,
    docUnavailabilty,
  } = useAppSelector((state) => state.appointments)

  const { isLoading } = useAppSelector((state) => state.appointment)
  const [selectedItem, setSelectedItem] = useState({
    name: 'Select All',
    _id: '',
  })

  const [selectedBranchItem, setSelectedBranchItem] = useState({
    name: 'Select Branch',
    _id: '',
  })
  const [doctorResources, setDoctorResources] = useState(resources)
  const [physioModal, setPhysioModal] = useState(false)
  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)
  const lastPage = Math.ceil(totalCount / itemsPerPage)
  const [popupData, setpopupData] = useState({})
  const { branchData } = useAppSelector((state) => state.login)
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    event: {},
    status: false,
  })
  const [apptDetailsPopup, setApptDetailsPopup] = useState<any>({
    status: false,
    apptData: {},
  })
  const [docAppointments, setDocAppointments] = useState<any>([])

  const [searchString, setSearchString] = useState('')

  const views: any[] = [
    'day',
    {
      label: 'Week',
      value: 'workWeek',
      type: 'week',
      shortcutKey: 'W',
    },
    'month',
  ]
  const hideAllDay = true
  const nonworkingDays: any[] = []
  const hideNonworkingWeekdays = true
  const firstDayOfWeek = 1
  const viewSelectorType = 'auto'
  const groups = ['_id']
  const timelineDayScale = 'halfHour'

  // open custom popup
  const handleCutomPopup = (e: any) => {
    dispatch(setRoomBookingDetailById(e?.detail?.item))
    const todayDate = new Date()
    const calDate = new Date(e?.detail?.item?.dateStart)
    setpopupData({ ...popupData, ...e?.detail?.item })
    e.preventDefault()

    if (calDate < todayDate && !e?.detail?.item?.appt_id) {
      dispatch(
        setMessage({
          message: t('BookingConfirmation.PastApt'),
          type: warning,
        })
      )
    } else if (
      e?.detail?.item?.appt_status === 'COMPLETED' ||
      e?.detail?.item?.appt_status === 'NOSHOW' ||
      e?.detail?.item?.appt_status === 'CANCELLED' ||
      e?.detail?.item?.appt_status === 'UNAVAILABLE'
    ) {
      return
    } else {
      setPhysioModal(true)
    }
  }
  // useeffect for set branch
  useEffect(() => {
    dispatch(addBranchData(selectedBranchItem))
  }, [dispatch, selectedBranchItem])

  useEffect(() => {
    let data = {
      search: searchString,
      page: currentPage,
      pageSize: itemsPerPage,
      order_by: { name: 1 },
      is_active: true,
      type: 'OPD',
    }
    dispatch(getAllRoomsForCalnedar(requestGenerator(data)))
  }, [dispatch, currentPage, itemsPerPage, searchString])

  // get all doctor's appoinments
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getAllPhysioAppointments(
          requestGenerator({ branch_id: selectedBranchItem?._id })
        )
      )
    }, 60000)
    return () => clearInterval(interval)
  }, [dispatch, selectedBranchItem?._id])

  useEffect(() => {
    selectedBranchItem?._id &&
      dispatch(
        getAllPhysioAppointments(
          requestGenerator({ branch_id: selectedBranchItem?._id })
        )
      )
  }, [dispatch, selectedBranchItem?._id])

  // go to previous page
  const handlePrevious = () => {
    if (searchString?.length > 0) {
      setSearchString('')
    }
    currentPage !== 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)
  }

  // go to next page
  const handleNext = () => {
    if (searchString?.length > 0) {
      setSearchString('')
    }
    currentPage < lastPage
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage)
  }

  // login for filter doctors
  useEffect(() => {
    if (selectedItem?._id === '') {
      setDoctorResources(resources)
    } else {
      let tempArr = []
      tempArr =
        resources?.length > 0 &&
        resources[0]?.dataSource?.filter(
          (item: any) => item?.id === selectedItem?._id
        )
      setDoctorResources([
        {
          label: 'Rooms',
          value: '_id',
          dataSource: tempArr || [],
        },
      ])
    }
  }, [resources, selectedItem, searchString])

  const handleStatusConfirmationAPICall = (data: any) => {
    let reqData: any = {
      appointment_id: apptDetailsPopup?.apptData?.appt_id,
      status: 'CANCELLED',
      reason: data.reason,
    }
    dispatch(cancelAppointmentStatus(requestGenerator(reqData))).then((e) => {
      if (e.type === `${CANCEL_APPOINTMENT}/fulfilled`) {
        dispatch(
          getAllPhysioAppointments(
            requestGenerator({ branch_id: selectedBranchItem?._id })
          )
        )
        setDeleteConfirmation({ event: {}, status: false })
        setApptDetailsPopup({ status: false, apptData: {} })
      }
    })
  }

  // disable delete
  const handleItemChanging = (e: any) => {
    if (
      e?.detail?.type === 'removing' &&
      e?.detail?.item?.backgroundColor === '#B11313'
    ) {
      e.preventDefault()
    } else if (e?.detail?.type === 'removing') {
      e.preventDefault()
      setDeleteConfirmation({ event: e, status: true })
    }
  }
  // data cleanup
  useEffect(() => {
    return () => {
      dispatch(clearData())
    }
  }, [dispatch])

  const [reoladDate, setReloadDate] = useState(new Date())

  // reolad
  const handleReloadButton = () => {
    dispatch(
      getAllPhysioAppointments(
        requestGenerator({ branch_id: selectedBranchItem?._id })
      )
    )
    setReloadDate(new Date())
  }

  // handle more appointment information

  const handleEventMenuOpening = (e: any) => {
    e.preventDefault()
    const isUnavailable = e?.detail?.eventObj[0]?.type === 'unavailability'
    if (!isUnavailable) {
      setApptDetailsPopup({
        status: true,
        apptData: e?.detail?.eventObj[0] || {},
      })
    }
  }
  useEffect(() => {
    branchData?.branches?.length > 0
      ? setSelectedBranchItem({
          name: branchData?.branches[0].name,
          _id: branchData?.branches[0]._id,
        })
      : setSelectedBranchItem({ name: 'Select Branch', _id: '' })
    setSelectedItem({ name: 'Select All', _id: '' })
  }, [branchData?.branches])

  const dispatchDate = (date: any) => {
    let formatDate = moment(date).format('YYYY-MM-DD')
    dispatch(setCurrentViewDate(formatDate))
  }

  useEffect(() => {
    const date = new Date()
    const caledarState = scheduler?.current?.getState()
    dispatchDate(caledarState?.dateCurrent ? caledarState?.dateCurrent : date)
  }, [])

  const handleDateChange = (e: any) => {
    const caledarState = scheduler?.current?.getState()
    dispatchDate(caledarState?.dateCurrent)
  }
  useEffect(() => {
    const data = {
      type: 'room',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getDocUnavailability(requestGenerator(data)))
  }, [dispatch])

  const unAvailableAppointments = useMemo(() => {
    if (docUnavailabilty && docUnavailabilty?.length > 0) {
      return getAptDataFromUnavailable(docUnavailabilty, true)
    } else {
      return []
    }
  }, [docUnavailabilty])

  useEffect(() => {
    setDocAppointments([...dataSource, ...unAvailableAppointments])
  }, [dataSource, unAvailableAppointments])
  return (
    <>
      {apptDetailsPopup.status && (
        <Popup
          Children={AppointmentDetails}
          popData={apptDetailsPopup}
          handleClose={(e) => {
            setApptDetailsPopup({ status: false, apptData: {} })
          }}
          handleOpen={(event: any) =>
            setDeleteConfirmation({ event: event, status: true })
          }
        />
      )}
      {deleteConfirmation?.status && (
        <Popup
          Children={StatusConfirmationPopupV3}
          handleClose={() =>
            setDeleteConfirmation({ event: {}, status: false })
          }
          message={t('Common.Are you sure') || 'Are you sure ?'}
          heading={
            t('StatusConfirmation.Cancel appointment') || 'Cancel appointment'
          }
          handleOpen={handleStatusConfirmationAPICall}
        />
      )}

      <CustomModal
        showModal={physioModal}
        closeModal={() => {
          setPhysioModal(false)
        }}
        title={t('RoomBooking.Room Booking') || 'Room Booking'}
        width="65dvw"
        height="95dvh"
      >
        <PhysioAppointmentBooking closeModal={() => setPhysioModal(false)} />
      </CustomModal>

      {loading || doctorLoading || isLoading ? <Loader /> : null}
      <div className={styles.dropdownPaginationContainer}>
        <div className={styles.dropdownMainContainer}>
          <DropdownV2
            data={roomData}
            type={t('IPDBooking.Room') || 'Room'}
            keyName="room_name"
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleClick={(item: any) => {
              setSelectedItem({ name: item?.room_name, _id: item?._id })
            }}
          />

          <DropdownV2
            data={branchData?.branches}
            type={t('Header.Branch') || 'Branch'}
            keyName="name"
            customClass={styles.branchDropdown}
            selectedItem={selectedBranchItem}
            setSelectedItem={setSelectedBranchItem}
            notShowAllOption={true}
            handleClick={(item: any) => {
              setSelectedBranchItem({ name: item?.name, _id: item?._id })
              setSelectedItem({ name: 'Select All', _id: '' })
            }}
          />
        </div>
        <div className={styles.reloadPaginationComponent}>
          <ReloadButton
            customClass={styles.mobileAppointmentReloadButton}
            handleClick={handleReloadButton}
            reoladDate={reoladDate}
          />
          <PaginationV2
            handleNext={() => handleNext()}
            handlePrevious={() => handlePrevious()}
            handleReset={() => setCurrentPage(1)}
            nextPage={hasMorePage}
            previousPage={true}
          />
        </div>
      </div>
      {resources?.length > 0 && resources[0]?.dataSource?.length > 0 ? (
        <Scheduler
          ref={scheduler}
          id="scheduler"
          dataSource={docAppointments}
          views={views}
          maxEventsPerCell={30}
          hideAllDay={hideAllDay}
          hourStart={0}
          hourEnd={23}
          nonworkingDays={nonworkingDays}
          hideNonworkingWeekdays={hideNonworkingWeekdays}
          firstDayOfWeek={firstDayOfWeek}
          viewSelectorType={viewSelectorType}
          groups={groups}
          timelineDayScale={timelineDayScale}
          resources={doctorResources}
          onEditDialogOpening={(e) => handleCutomPopup(e)}
          disableDrag={true}
          disableDrop={true}
          disableResize={true}
          onItemChanging={(e) => handleItemChanging(e)}
          onDateChange={(e) => handleDateChange(e)}
          unfocusable={true}
          onContextMenuOpening={(ev: any) => {
            ev.preventDefault()
          }}
          onEventMenuOpening={(e) => handleEventMenuOpening(e)}
          onViewChanging={(e) => {}}
          eventRenderMode={'classic'}
          /*restrictedHours={[10]} */
          // disableSelection={disableContextMenu}
          // disableContextMenu={true}
          // ** Restrcting available slots ** // onContextMenuOpening={(e) => handleRestricedHours(e)}
          // onEditDialogOpen={(e) => handleEditDialogOpen(e)}
        />
      ) : (
        <div style={{ margin: '10px' }}>
          <img
            src={noRecordImage}
            alt="no records found"
            style={{ width: '100%' }}
          />
        </div>
      )}
    </>
  )
}

export default RoomSchedular
