// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainLayout_mainContainer__FKdFT {\n  display: flex;\n  flex-direction: row;\n}\n.mainLayout_mainContainer__FKdFT .mainLayout_headerChildrenContainer__F0yea {\n  width: 88%;\n}\n.mainLayout_mainContainer__FKdFT .mainLayout_customHeaderChildrenContainer__nOomZ {\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/main-layout/mainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAAE;EACE,UAAA;AAEJ;AACE;EACE,WAAA;AACJ","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: row;\n  .headerChildrenContainer {\n    width: 88%;\n    // margin: 0px 20px 0px 0px;\n  }\n  .customHeaderChildrenContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "mainLayout_mainContainer__FKdFT",
	"headerChildrenContainer": "mainLayout_headerChildrenContainer__F0yea",
	"customHeaderChildrenContainer": "mainLayout_customHeaderChildrenContainer__nOomZ"
};
export default ___CSS_LOADER_EXPORT___;
