import { FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './statusDropdown.module.scss'
import { useAppSelector } from '../../../../hooks'

import { DropDownIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import { colorSchemeData } from '../../../../constants/data'
import { filterAppointmentStatusList } from '../../../../utils/utils'

interface IStatusDropdown {
  appointmentStatus?: any
  isTodayAppointment?: boolean
  handleStatusChange?: any
  isDisable?: boolean
  handleStatus?: any
  showOption?: any
  setShowOption?: any
}

const MasterAppointmentStatusDropdown: FC<IStatusDropdown> = ({
  appointmentStatus,
  isDisable,
  handleStatus,
  showOption,
  setShowOption,
}) => {
  const [statusValue, setStatusValue] = useState<any>({
    label: appointmentStatus || '',
    color: '',
    bgColor: '',
  })

  const statusRef = useRef<any>()
  const { colorSchemeList } = useAppSelector((state) => state.login)
  useEffect(() => {
    const found = colorSchemeList?.find(
      (item: any) => item?.label === appointmentStatus
    )
    setStatusValue({
      label: appointmentStatus || '',
      color: found?.colorCode,
      bgColor: found?.bgColor,
    })
  }, [appointmentStatus, colorSchemeList])

  // function for close dropdown
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showOption &&
        statusRef.current &&
        !statusRef.current.contains(e.target)
      ) {
        setShowOption(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showOption, setShowOption])

  const [dropdownData, setDropdownData] = useState(colorSchemeData)

  const getDropdown = useCallback(() => {
    return filterAppointmentStatusList(colorSchemeList, appointmentStatus)
  }, [colorSchemeList, appointmentStatus])

  useEffect(() => {
    setDropdownData(getDropdown())
  }, [getDropdown])

  const handleOpenDropdown = () => {
    setShowOption(!showOption)
  }

  return (
    <>
      <div className={styles.dropdownContainer} ref={statusRef}>
        <div
          className={styles.status}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '120px',
            cursor:
              dropdownData.length === 0 || isDisable ? 'default' : 'pointer',
            justifyContent:
              dropdownData.length === 0 || isDisable
                ? 'center'
                : 'space-between',
            color: statusValue?.color,
            backgroundColor: statusValue?.bgColor,
          }}
          onClick={() => (!isDisable ? handleOpenDropdown() : {})}
        >
          <p
            style={{
              cursor:
                dropdownData.length === 0 || isDisable ? 'default' : 'pointer',
            }}
          >
            {statusValue?.label}
          </p>
          {dropdownData.length > 0 && !isDisable && (
            <DropDownIcon
              fillColor={colors.black1}
              //   handleClick={() => setShowOption(!showOption)}
              customClass={styles.iconStyle}
            />
          )}
        </div>
        {showOption && (
          <div className={styles.optionContainer}>
            {dropdownData?.map((item: any) => {
              if (item?.label !== statusValue?.label) {
                return (
                  <div onClick={() => handleStatus(item)}>
                    <p
                      className={styles.status}
                      style={{
                        color: item?.colorCode,
                        backgroundColor: item?.bgColor,
                        cursor: [
                          'RESCHEDULED',
                          'INPROGRESS',
                          'COMPLETED',
                        ].includes(item?.label)
                          ? 'default'
                          : 'pointer',
                      }}
                    >
                      {item.label}
                    </p>
                  </div>
                )
              } else {
                return ''
              }
            })}
          </div>
        )}
      </div>
    </>
  )
}

export default MasterAppointmentStatusDropdown
