import { FC, useState, useEffect } from 'react'

import { colors } from '../../../../constants/color'
import styles from './balanceSheetLiabilityModal.module.scss'
import { CloseIcon } from '../../../../components/common/svg-components'
import Pagination from '../../../../components/common/pagination/Pagination'
import { liabilityViewBalanceTableHeaderData } from '../balanceSheetTableData'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

interface IBalanceSheetLiabilityModal {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const BalanceSheetLiabilityModal: FC<IBalanceSheetLiabilityModal> = ({
  handleClose,
  popData,
}) => {
  const [totalCreditAmount, setTotalCreditAmount] = useState(0)
  const [totalDebitAmount, setTotalDebitAmount] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [data, setData] = useState([])
  // Credit Amount
  useEffect(() => {
    const calculatedTotalCreditAmount = popData?.type?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.credit_amount)
        return sum + creditAmount
      },
      0
    )

    setTotalCreditAmount(calculatedTotalCreditAmount)
    const debitAmount = popData?.type?.reduce((sum: any, row: any) => {
      const debitAmount = parseFloat(row?.debit_amount)
      return sum + debitAmount
    }, 0)

    setTotalDebitAmount(debitAmount)
  }, [popData])

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()
  useEffect(() => {
    const typeArr =
      popData?.type && popData?.type?.length > 0
        ? popData?.type?.map((item: any) => item?.voucher)
        : []
    const tempArr = typeArr && typeArr?.length > 0 ? typeArr?.flat() : []
    setData(tempArr)
  }, [popData])

  return (
    <div
      className={styles.balanceViewModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.balanceViewModalHeading}>
        {' '}
        {popData?.name} {t('CallCenterAdmin.Details')}{' '}
      </h1>
      <hr className={styles.balanceViewDivider} />
      <div className={styles.tableData}>
        <TableV2
          tableHeaderData={liabilityViewBalanceTableHeaderData}
          tableRowData={data}
          active={false}
        />
      </div>
      {/* Pagination */}
      {popData?.type?.length > 10 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
      <div className={styles.tableLabelContainer}>
        <span className={styles.debitText}>
          {t('Voucher.Total_Debit_Amount')}: $ {totalDebitAmount}
        </span>
        <span className={styles.creditText}>
          {t('Voucher.Total_Credit_Amount')}: $ {totalCreditAmount}
        </span>
      </div>
    </div>
  )
}

export default BalanceSheetLiabilityModal
