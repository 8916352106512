import { DeleteIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import styles from './diagnosisRequestHeaderData.module.scss'
import { t } from 'i18next'

export const diagnosisRequestHeaderData: any = [
  {
    Header: t('RequestDiagnosis.TESTTYPE'),
    accessor: 'test_type',
    disableSortBy: true,
  },
  {
    Header: t('RequestDiagnosis.TESTNAME'),
    // accessor: "test_name",
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {/* {props?.row?.original?.test_name ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onRowClick(props?.row?.original);
              }}
            >
              View
            </span>
          ) : (
            "-"
          )} */}
          {props?.row?.original?.test_name
            ? props?.row?.original?.test_name
            : '-'}
        </>
      )
    },
  },
  {
    Header: t('CallCenterAdmin.CODE'),
    accessor: 'code',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.code ? <>{props?.row?.original?.code}</> : '-'}
        </>
      )
    },
  },
  {
    Header: t('RequestDiagnosis.PRIORITY'),
    accessor: 'priority',
    disableSortBy: true,
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.price ? (
            <span>$ {props?.row?.original?.price}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
    disableSortBy: true,
  },
  {
    Header: t('RequestDiagnosis.BILLABLE'),
    accessor: 'is_billable',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {/* {props?.row?.original?.is_billable ? ( */}
          <span>
            {props?.row?.original?.is_billable
              ? t('Common.Yes')
              : t('Common.No')}
          </span>
          {/* ) : (
            "No"
          )} */}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'test_notes',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.test_notes ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <DeleteIcon
          fillColor={colors.grey4}
          customClass={styles.iconStyle}
          handleClick={() => {
            props?.onPopClose(props?.row?.original)
          }}
        />
      )
    },
  },
]
