import { FC, useEffect } from 'react'
import Tabs from '../../components/core/tab/Tabs'
import PatientInformationForm from './patient_information_form/PatientInformationForm'
import { dentistDiagnosisTabData } from '../../constants/data'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { clearDentalDiagnosisDetails } from '../../redux/features/dentist-diagnosis/dentistDiagnosisSlice'
import { useTranslation } from 'react-i18next'

const DentistDiagnosisLayout: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation().pathname
  const { diagnosisDetails } = useAppSelector((state) => state.dentistDiagnosis)
  const { t } = useTranslation()

  useEffect(() => {
    if (location === '/patientdentaldiagnosis') {
      navigate('/patientdentaldiagnosis/diagnosis')
    }
  }, [location])

  // clear dental diagnosis details data on component remove
  useEffect(() => {
    return () => {
      dispatch(clearDentalDiagnosisDetails())
    }
  }, [])

  const getDiagnosisStatus = (status: string) => {
    if (status == 'NS') {
      return (
        <span
          style={{
            padding: '4px',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '8px',
            textTransform: 'uppercase',
          }}
        >
          Not Started
        </span>
      )
    } else if (status == 'S') {
      return (
        <span
          style={{
            padding: '4px',
            backgroundColor: 'yellow',
            color: 'black',
            borderRadius: '8px',
            textTransform: 'uppercase',
          }}
        >
          Started
        </span>
      )
    } else if (status == 'E') {
      return (
        <span
          style={{
            padding: '4px',
            backgroundColor: 'green',
            color: 'white',
            borderRadius: '8px',
            textTransform: 'uppercase',
          }}
        >
          Completed
        </span>
      )
    } else {
      return ' - '
    }
  }

  return (
    <>
      <PatientInformationForm />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
          paddingBottom: '12px',
          borderBottom: '1px solid lightgrey',
        }}
      >
        <div style={{ paddingLeft: '12px' }}>
          <b>
            {t('DentistDiagnosis.DiagnosisNo')} :{' '}
            {diagnosisDetails && diagnosisDetails.diagnosis_number
              ? '#' + diagnosisDetails.diagnosis_number
              : ' - '}
          </b>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <b style={{ display: 'inline-block' }}>
            {t('AptDetails.Status')} :{' '}
            {diagnosisDetails
              ? getDiagnosisStatus(diagnosisDetails.diagnosis_stage)
              : ' - '}
          </b>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '40px',
        }}
      >
        <Tabs tabData={dentistDiagnosisTabData} />
        <Outlet />
      </div>
    </>
  )
}

export default DentistDiagnosisLayout
