import { FC, useState } from 'react'
import Button from '../../../components/common/button/Button'
import styles from './add-new.module.scss'
import { colors } from '../../../constants/color'
import { EmployeeArray, PaymentArray } from '../../../constants/data'
import { useNavigate } from 'react-router-dom'
import { CloseIcon } from '../../../components/common/svg-components'
// import { Toggle } from 'react-toggle-component'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addnewValidators } from '../../../form-validators/addnewValidators'
import {
  EMPLOYEES_BENIFT,
  EMPLOYEES_NAME,
  EMP_LEAVE,
  EMP_SALARY,
  GRATUITY,
  NET_PAYABLE,
  PAYMENT_DETAILS,
  PAYMENT_MODE,
  PAYMENT_STATUS,
  TOTAL_DEDUCTION,
} from './addNewConstant'
import { IAddnew } from './addNewInterface'
import ToggleSwitchV2 from '../../../components/common/toggle-switch/ToggleSwitchV2'
import { t } from 'i18next'

interface INew {}

const New: FC<INew> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddnew>({})
  const onSubmit: SubmitHandler<IAddnew> = (data) => {
    setShowDiv(!showDiv)
  }
  const handle = () => {
    navigate('/payroll/services')
  }
  const navigate = useNavigate()
  const is_active = true
  const [showDiv, setShowDiv] = useState(false)

  return (
    <>
      <div className={styles.addContainer}>
        <h1>{t('Payroll.Employee_Info')}</h1>
        <form>
          <div className={styles.div}>
            <div className={styles.formFieldContainer}>
              <label>{t('Attendance.Employee_Name')}</label>
              <div className={styles.inputFieldContainer}>
                <select
                  className={styles.selectInputField}
                  {...register(
                    EMPLOYEES_NAME,
                    addnewValidators[EMPLOYEES_NAME]
                  )}
                >
                  <option value="">{t('ReportGenerator.Select')}</option>
                  {EmployeeArray?.map((EmployeeArray, index) => (
                    <option value={EmployeeArray} key={index}>
                      {EmployeeArray}
                    </option>
                  ))}
                </select>
                {errors[EMPLOYEES_NAME] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[EMPLOYEES_NAME].message}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className={styles.buttonConatiner}>
          <Button
            title={t('Attendance.Go') || 'Go'}
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            customClass={styles.button}
          />
        </div>
      </div>
      {showDiv && (
        <div className={styles.addContainer}>
          <h1>{t('Payroll.Employee_Information')}</h1>
          <form>
            <div className={styles.salary}>
              <div className={styles.row}>
                <label>{t('ManageStaff.Designation')}</label>
                <input type="text" placeholder="MARKETING MANAGER" />
              </div>
              <div className={styles.row}>
                <label>{t('ManageStaff.Department')}</label>
                <input type="text" placeholder="Marketing" />
              </div>
              <div className={styles.row}>
                <label>{t('AddEmp.Basic Salary')} ($)</label>
                <input type="text" placeholder="Basic Salary ($)" />
              </div>
              <div className={styles.row}>
                <label>{t('AddEmp.Joining Date')}</label>
                <input type="text" placeholder="2018-05-12" />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.EOS_Date')}</label>
                <input
                  type="date"
                  min={new Date().toISOString().split('T')[0]}
                  max="9999-12-31"
                />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.Days_Worked')}</label>
                <input type="text" placeholder="0" />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.Date_of_Resign')}</label>
                <input
                  type="date"
                  min={new Date().toISOString().split('T')[0]}
                  max="9999-12-31"
                />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.Unpaid_Days')}</label>
                <input
                  type="date"
                  min={new Date().toISOString().split('T')[0]}
                  max="9999-12-31"
                />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.Last_Working_Day')}</label>
                <input type="text" placeholder="0" />
              </div>
              <div className={styles.row}>
                <label>{t('Payroll.Days_Eligible')}</label>
                <input type="text" placeholder="0" />
              </div>

              <div className={styles.row}>
                <label>{t('Payroll.Separation_Type')}</label>
                <select className={styles.selectInputField}>
                  <option value="">{t('Payroll.Paid')}</option>
                  {EmployeeArray?.map((EmployeeArray, index) => (
                    <option value={EmployeeArray} key={index}>
                      {EmployeeArray}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      )}
      {showDiv && (
        <div className={styles.note}>
          <div className={styles.addContainer}>
            <h1>{t('Payroll.Gratuity_Information')}</h1>
            <div className={styles.row}>
              <label>{t('Payroll.Payable_Days')}</label>
              <input type="text" placeholder="" />
            </div>

            <div className={styles.row}>
              <label>{t('Payroll.Payable_Amount')}</label>
              <input type="text" placeholder="" />
            </div>

            <div className={styles.notes}>
              <h3>{t('Common.Notes')}</h3>
              <li>
                10 {t('Payroll.yrs')} ={' '}
                {t('Payroll.Full amount of salary x number of years')}
              </li>
              <li>
                6-10 {t('Payroll.yrs')} ={' '}
                {t('Payroll.2/3rd of salary x number of years')}
              </li>
              <li>
                5-10 {t('Payroll.yrs')} ={' '}
                {t('Payroll.50% of salary x number of years')}
              </li>
              <li>
                3{t('Payroll.yrs')} = {t('Payroll.None')}
              </li>
            </div>
          </div>

          <div className={styles.addContainer1}>
            <h1>{t('Payroll.Leaves_Salary')}</h1>
            <div className={styles.row}>
              <label>{t('Payroll.Payable_Days')}</label>
              <input type="text" placeholder="" />
            </div>

            <div className={styles.row}>
              <label>{t('Payroll.Payable_Amount')}</label>
              <input type="text" placeholder="" />
            </div>
          </div>
        </div>
      )}
      {showDiv && (
        <div className={styles.usd}>
          <div className={styles.addContainer}>
            <h1>{t('Payroll.Benefits')}</h1>
            <div className={styles.data}>
              <div className={styles.row}>
                <input type="text" placeholder="Benefits 1" />
                <input type="text" placeholder="0" />
                <label>{t('Payroll.USD')}</label>
                <CloseIcon
                  customClass={styles.closeIconStyle}
                  fillColor={colors.red1}
                />
              </div>
              <div className={styles.row}>
                <input type="text" placeholder="Benefits 1" />
                <input type="text" placeholder="Value" />
                <label>{t('Payroll.USD')}</label>
                <CloseIcon
                  customClass={styles.closeIconStyle}
                  fillColor={colors.red1}
                />
              </div>
            </div>

            <div className={styles.buttonConatiner}>
              <Button
                title={t('ActionBar.Add') || 'Add'}
                customClass={styles.button}
              />
            </div>
          </div>
          <div className={styles.addContainer}>
            <h1>{t('Payroll.Deduction')}</h1>
            <div className={styles.data}>
              <div className={styles.row}>
                <input type="text" placeholder="Deduction" />
                <input type="text" placeholder="Value" />
                <label>{t('Payroll.USD')}</label>
                <CloseIcon
                  customClass={styles.closeIconStyle}
                  fillColor={colors.red1}
                />
              </div>
              <div className={styles.row}>
                <input type="text" placeholder="Deduction" />
                <input type="text" placeholder="Value" />
                <label>{t('Payroll.USD')}</label>
                <CloseIcon
                  customClass={styles.closeIconStyle}
                  fillColor={colors.red1}
                />
              </div>
            </div>

            <div className={styles.buttonConatiner}>
              <Button
                title={t('ActionBar.Add') || 'Add'}
                customClass={styles.button}
              />
            </div>
          </div>
        </div>
      )}
      {showDiv && (
        <div className={styles.setttelment}>
          <h1>{t('Payroll.Final Settlement Details')}</h1>
          <form>
            <div className={styles.salary}>
              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('AddEmp.Basic Salary')}($)(+)</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="0.00"
                      {...register(EMP_SALARY, addnewValidators[EMP_SALARY])}
                    />
                  </div>
                </div>
                {errors[EMP_SALARY] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[EMP_SALARY].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label className={styles.label}>
                    {t('Payroll.Gratuity')} ($)(+)
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="0.00"
                      {...register(GRATUITY, addnewValidators[GRATUITY])}
                    />
                  </div>
                </div>
                {errors[GRATUITY] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[GRATUITY].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Acured_Leaves')} ($)(+)</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(EMP_LEAVE, addnewValidators[EMP_LEAVE])}
                    />
                  </div>
                </div>
                {errors[EMP_LEAVE] && (
                  <div className={styles.errorContainer}>
                    {/* <span className={styles.extraSpan}></span> */}
                    <p className={styles.dashboardFormError}>
                      {errors[EMP_LEAVE].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Total_Benifits')} ($)(+)</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(
                        EMPLOYEES_BENIFT,
                        addnewValidators[EMPLOYEES_BENIFT]
                      )}
                    />
                  </div>
                </div>
                {errors[EMPLOYEES_BENIFT] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[EMPLOYEES_BENIFT].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Total_Deduction')} ($)(-)</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(
                        TOTAL_DEDUCTION,
                        addnewValidators[TOTAL_DEDUCTION]
                      )}
                    />
                  </div>
                </div>
                {errors[TOTAL_DEDUCTION] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[TOTAL_DEDUCTION].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label className={styles.label}>
                    {t('Payroll.Net_Payable')} ($)(+)
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(NET_PAYABLE, addnewValidators[NET_PAYABLE])}
                    />
                  </div>
                </div>
                {errors[NET_PAYABLE] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[NET_PAYABLE].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('InvoicePayment.Payment Mode')}</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(
                        PAYMENT_MODE,
                        addnewValidators[PAYMENT_MODE]
                      )}
                    />
                  </div>
                </div>
                {errors[PAYMENT_MODE] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[PAYMENT_MODE].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Payment_Details')}</label>
                  <div className={styles.inputFieldContainer}>
                    <input
                      type="text"
                      placeholder="2049.0"
                      {...register(
                        PAYMENT_DETAILS,
                        addnewValidators[PAYMENT_DETAILS]
                      )}
                    />
                  </div>
                </div>
                {errors[PAYMENT_DETAILS] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[PAYMENT_DETAILS].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Payment_Status')}</label>
                  <div className={styles.inputFieldContainer}>
                    <select
                      className={styles.selectInputField}
                      {...register(
                        PAYMENT_STATUS,
                        addnewValidators[PAYMENT_STATUS]
                      )}
                    >
                      <option value="">{t('Payroll.Paid')}</option>
                      {PaymentArray?.map((PaymentArray, index) => (
                        <option value={PaymentArray} key={index}>
                          {PaymentArray}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {errors[PAYMENT_STATUS] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className={styles.dashboardFormError}>
                      {errors[PAYMENT_STATUS].message}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.row}>
                <div className={styles.formFieldContainer}>
                  <label>{t('Payroll.Make Employee Inactive')} ?</label>
                  {/* <Toggle
                    name="toggle-2"
                    borderColor="none"
                    knobColor="white"
                    leftBackgroundColor="grey"
                    rightBackgroundColor="green"
                    value="test11"
                  /> */}
                  <ToggleSwitchV2 />
                </div>
              </div>
            </div>

            <div className={styles.buttonConatiner}>
              <Button
                title={t('Common.Submit') || 'Submit'}
                type="submit"
                handleClick={handleSubmit(handle)}
                customClass={styles.button}
              />
              <Button
                title={t('Common.Back') || 'Back'}
                customClass={styles.resetButtonStyle}
                handleClick={() => navigate('/payroll/services')}
              />
            </div>
          </form>
        </div>
      )}
    </>
  )
}
export default New
