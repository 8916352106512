import { FC, useState, useEffect } from 'react'

import { colors } from '../../../../constants/color'
import styles from './balanceSheetAssetsModal.module.scss'
import { CloseIcon } from '../../../../components/common/svg-components'
import { assetsViewBalanceTableHeaderData } from '../balanceSheetTableData'
import Pagination from '../../../../components/common/pagination/Pagination'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

interface IBalanceSheetAssetsModal {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const BalanceSheetAssetsModal: FC<IBalanceSheetAssetsModal> = ({
  heading,
  handleClose,
  popData,
}) => {
  const [totalDebitAmount, setTotalDebitAmount] = useState(0)
  const [totalCreditAmount, setTotalCreditAmount] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [data, setData] = useState([])
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  // Assets Amount

  useEffect(() => {
    const calculatedTotalDebitAmount = popData?.reduce((sum: any, row: any) => {
      const debitAmount = parseFloat(row?.debit_amount)
      return sum + debitAmount
    }, 0)
    setTotalDebitAmount(calculatedTotalDebitAmount)
    const calculatedTotalCreditAmount = popData?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.credit_amount)
        return sum + creditAmount
      },
      0
    )
    setTotalCreditAmount(calculatedTotalCreditAmount)
  }, [popData])

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()
  useEffect(() => {
    const tempArr =
      popData && popData?.length > 0
        ? popData?.map((item: any) => item?.voucher)
        : []

    const flat = tempArr && tempArr?.length > 0 ? tempArr?.flat() : []
    setData(flat)
  }, [popData])
  return (
    <div
      className={styles.balanceViewModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.balanceViewModalHeading}>
        {t('Reports.Assets_Details')}
      </h1>
      <hr className={styles.balanceViewDivider} />
      <div className={styles.tableData}>
        <TableV2
          tableHeaderData={assetsViewBalanceTableHeaderData}
          // tableRowData={
          //   popData[0]?.voucher.length > 0 ? popData[0]?.voucher : []
          // }
          tableRowData={data}
          active={false}
        />
      </div>
      {/* Pagination */}
      {popData?.length > 10 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
      <div className={styles.tableLabelContainer}>
        <span className={styles.debitText}>
          {t('Voucher.Total_Debit_Amount')}: $ {totalDebitAmount}
        </span>
        <span className={styles.creditText}>
          {t('Voucher.Total_Credit_Amount')}: $ {totalCreditAmount}
        </span>
      </div>
    </div>
  )
}

export default BalanceSheetAssetsModal
