import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  GET_USER_ROLE,
  REPORT_BUILDER_POC,
  UPDATE_REPORT_MAPPING_URL,
  CREATE_REPORT_QUERY_URL,
  GET_REPORT_BUILDER_CONFIG_URL,
  GET_REPORT_BUILDER_URL,
  GET_REPORTS_TYPE_URL,
  GET_DYNAMIC_REPORT_BY_QUERY_URL,
  UPDATE_REPORT_QUERY_URL,
  UPDATE_REPORT_STATUS_URL,
  PATIENT_LIST_REPORT,
  INVOICE_WISE_SALES,
  SERVICE_WISE_SALES_SUMMARY,
  COLLECTION_DAY_WISE_SUMMARY,
  DOCTOR_WISE_COLLECTION_SUMMARY,
  SERVICE_WISE_SALES,
  PATIENT_STATEMENT,
  PATIENT_SUMMARY_URL,
  REVENUE_URL,
  PATIENT_SUMMARY_FILTER,
  REVENUE_ANYLYTICS_FILTER,
} from '../../../config/config'

export const getRoleUser = (data: IAPIPayload) => {
  return axios.post(GET_USER_ROLE, data)
}

export const updaterolestatus = (data: IAPIPayload) => {
  return axios.post(GET_USER_ROLE, data)
}

export const reportMapping = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_MAPPING_URL, data)
}

export const reportPoc = (data: IAPIPayload) => {
  return axios.post(REPORT_BUILDER_POC, data)
}

export const createReportQuery = (data: IAPIPayload) => {
  return axios.post(CREATE_REPORT_QUERY_URL, data)
}

export const updateReportBuilderQuery = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_QUERY_URL, data)
}

export const getReportBuilderConfig = (data: IAPIPayload) => {
  return axios.post(GET_REPORT_BUILDER_CONFIG_URL, data)
}

export const getReportBuilder = (data: IAPIPayload) => {
  return axios.post(GET_REPORT_BUILDER_URL, data)
}

export const getReportsType = (data: IAPIPayload) => {
  return axios.post(GET_REPORTS_TYPE_URL, data)
}

export const getDynamicReportByQuery = (data: IAPIPayload) => {
  return axios.post(GET_DYNAMIC_REPORT_BY_QUERY_URL, data)
}

export const updateReportStatus = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_STATUS_URL, data)
}

export const getAllPatientListReport = (data: IAPIPayload) => {
  return axios.post(PATIENT_LIST_REPORT, data)
}

export const invoiceWiseSales = (data: IAPIPayload) => {
  return axios.post(INVOICE_WISE_SALES, data)
}

export const serviceWiseSalesSummary = (data: IAPIPayload) => {
  return axios.post(SERVICE_WISE_SALES_SUMMARY, data)
}

export const collectionDayWiseSummary = (data: IAPIPayload) => {
  return axios.post(COLLECTION_DAY_WISE_SUMMARY, data)
}

export const doctorWiseCollections = (data: IAPIPayload) => {
  return axios.post(DOCTOR_WISE_COLLECTION_SUMMARY, data)
}

export const serviceWiseSales = (data: IAPIPayload) => {
  return axios.post(SERVICE_WISE_SALES, data)
}

export const patientStatements = (data: IAPIPayload) => {
  return axios.post(PATIENT_STATEMENT, data)
}

// patient summary
export const patientSummary = (data: IAPIPayload) => {
  return axios.post(PATIENT_SUMMARY_URL, data)
}

// KPI revenue
export const revenue = (data: IAPIPayload) => {
  return axios.post(REVENUE_URL, data)
}


// patient summary filter

export const patientSummaryFilter = (data: IAPIPayload) => {
  return axios.post(PATIENT_SUMMARY_FILTER, data)
}

export const RevenueAnylticsFilter = (data: IAPIPayload) => {
  return axios.post(REVENUE_ANYLYTICS_FILTER, data)
}

