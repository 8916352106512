import moment from 'moment'
import { PrintIcon } from '../../components/common/svg-components'
import { t } from 'i18next'

export const viewReceiptHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.createdAt).format(
        'DD MMM YYYY'
      )
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('ViewReceipt.RECEIPT NO'),
    accessor: 'receipt_no',
  },
  {
    Header: t('Outstanding.OUTSTANDING AMOUNT'),
    Cell: ({ row }: any) => {
      return <span>{row?.original?.outstanding_amount ?? '-'}</span>
    },
  },
  {
    Header: t('ManageStaff.EMAIL'),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: true })
            }}
            style={{ cursor: 'pointer', color: '#0e26a3' }}
          >
            {t('Common.View')}
          </span>
        </>
      )
    },
  },
  {
    Header: t('SettledInvoice.PRINT'),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: false })
            }}
          >
            <PrintIcon />
          </span>
        </>
      )
    },
  },
]
